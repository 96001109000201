import moment from 'moment'
import { DATE_TO_DB } from 'app/constants'
import { TCustomerData } from 'types/customer.types'
import { TLoanerData } from 'types/loaner.types'
import { TAgreementData } from 'types/agreement.types'

/**
 * Create current date time as a string
 * @returns {string}
 */
export const now = () => moment().format(DATE_TO_DB)

/**
 * Map customer data to UI model
 * @param {Object} customer
 * @returns {Object} customer
 */
export const mapCustomerDataToUI = (
  customer: TCustomerData,
  withTimestamp: boolean = false
): TCustomerData => {
  const mappedData = {
    licence: customer.licence,
    firstname: customer.firstname,
    lastname: customer.lastname,
    phone: customer.phone,
    address: customer.address,
    email: customer.email,
    dob: customer.dob,
    gender: customer.gender,
    comments: customer.comments || []
  } as TCustomerData

  if (withTimestamp) {
    mappedData.createdAt = customer.createdAt
    mappedData.updatedAt = customer.updatedAt
    mappedData.deletedAt = customer.deletedAt
  }

  return mappedData
}

/**
 * Map loaner data to UI model
 * @param {Object} loaner
 * @returns {Object} loaner
 */
export const mapLoanerDataToUI = (
  loaner: TLoanerData,
  withTimestamp: boolean = false
): TLoanerData => {
  const mappedData = {
    rego: loaner.rego,
    make: loaner.make,
    model: loaner.model,
    year: loaner.year,
    colour: loaner.colour,
    transmission: loaner.transmission,
    availability: loaner.availability
  } as TLoanerData

  if (withTimestamp) {
    mappedData.createdAt = loaner.createdAt
    mappedData.updatedAt = loaner.updatedAt
    mappedData.deletedAt = loaner.deletedAt
  }

  return mappedData
}

/**
 * Map agreement data to UI model
 * @param {Object} agreement
 * @returns {Object} agreement
 */
export const mapAgreementDataToUI = (agreement: TAgreementData): TAgreementData => {
  return {
    rego: agreement.rego,
    make: agreement.make,
    model: agreement.model,
    year: agreement.year,
    firstname: agreement.firstname,
    lastname: agreement.lastname,
    licence: agreement.licence,
    returned: agreement.returned,
    loanerId: agreement.loanerId,
    createdAt: agreement.createdAt
  }
}
