import { takeEvery, call, put } from '@redux-saga/core/effects'
import toastr from 'Toastr.config'

import { exportData, importData, processFile } from 'api/importExport.api'
import { disableLoading, enableLoading } from '../actions'
import { processFileSuccess } from '../actions/importExport.actions'
import { IMPORT_EXPORT, IMPORT_EXPORT_MESSAGE } from 'app/constants'

import {
  TExportDataParam,
  TFileProccessParam,
  TImportDataParam,
  TImportFileInfo
} from 'types/importExport.types'

export default function* watchImportExportSaga() {
  yield takeEvery(IMPORT_EXPORT.PROCESS_FILE, handleProcessFile)
  yield takeEvery(IMPORT_EXPORT.IMPORT_DATA, handleImportData)
  yield takeEvery(IMPORT_EXPORT.EXPORT_DATA, handleExportData)
}

function* handleProcessFile(params: TFileProccessParam) {
  try {
    yield put(enableLoading())
    const content = (yield call(processFile, params.file)) as TImportFileInfo
    yield put(processFileSuccess(content))
    yield put(disableLoading())
    yield toastr.success(IMPORT_EXPORT_MESSAGE.PROCESS_FILE_SUCCESS)
  } catch (error) {
    yield put(disableLoading())
    yield toastr.error(IMPORT_EXPORT_MESSAGE.PROCESS_FILE_FAILED)
  }
}

function* handleImportData(params: TImportDataParam) {
  try {
    yield put(enableLoading())
    yield call(importData, params.opt, params.data)
    yield put(disableLoading())
    yield toastr.success(IMPORT_EXPORT_MESSAGE.IMPORT_SUCCESS)
  } catch (error) {
    yield put(disableLoading())
    yield toastr.error(IMPORT_EXPORT_MESSAGE.IMPORT_FAILED)
  }
}

function* handleExportData(params: TExportDataParam) {
  try {
    yield put(enableLoading())
    yield call(exportData, params.entity)
    yield put(disableLoading())
    yield toastr.success(IMPORT_EXPORT_MESSAGE.EXPORT_SUCCESS)
  } catch (error) {
    yield put(disableLoading())
    yield toastr.error(IMPORT_EXPORT_MESSAGE.EXPORT_FAILED)
  }
}
