import React, { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useFileUpload } from 'use-file-upload'
import isArray from 'lodash/isArray'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import { Row, Col } from 'app/components/common/grid'
import { FormButtonWrapper } from 'app/styles/form.styles'
import Spinner from 'app/components/common/spinner'
import Select from 'app/components/common/formElement/Select'
import Spacer from 'app/components/common/spacer'

import { getImportFileInfo, getLoadingState } from 'app/store/selector'
import {
  exportDataAction,
  importDataAction,
  processFile
} from 'app/store/actions/importExport.actions'

import { PageHeader, pageStyles, Section } from 'app/styles/page.styles'
import { buttonStyle } from 'app/styles/button.styles'

import { TImportOpt, TExportData } from 'types/importExport.types'

const entities = [
  { label: 'Select', value: '' },
  { label: 'Agreements', value: 'Agreements' },
  { label: 'Customers', value: 'Customers' },
  { label: 'Loaners', value: 'Loaners' }
]

const importMode = [
  { label: 'Select', value: '' },
  { label: 'Override', value: 'override' },
  { label: 'Duplicate', value: 'duplicate' }
]

const ImportExport = () => {
  const dispatch = useDispatch()
  const exportDataFormHook = useForm()
  const importDataFormHook = useForm()
  const classes = { ...pageStyles(), ...buttonStyle() }

  const [file, selectFile] = useFileUpload()
  const [fileName, setFileName] = useState<string>('')
  const loading = useSelector(getLoadingState)
  const fileCols = useSelector(getImportFileInfo)

  const exportData = (data: TExportData) => {
    dispatch(exportDataAction(data.entity))
  }

  const importData = (opt: TImportOpt) => {
    dispatch(importDataAction(opt, fileCols.data))
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <PageHeader>
        <h1>Import / Export</h1>
      </PageHeader>
      <Paper className={classes.paper}>
        <Row>
          <Col xs={6}>
            <Section>
              <h3>Import</h3>
              <Button
                className={classes.saveButton}
                onClick={() => {
                  selectFile(
                    {
                      accept:
                        '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                      multiple: false
                    },
                    file => {
                      if (!isArray(file)) {
                        setFileName(file.name)
                        dispatch(processFile(file))
                      }
                    }
                  )
                }}
              >
                Select a file
              </Button>
              {file && (
                <div>
                  File: <em>{fileName}</em>
                </div>
              )}
              <Spacer />
              {file && (
                <form onSubmit={importDataFormHook.handleSubmit(importData)}>
                  <Select
                    formHook={importDataFormHook}
                    label="Data import to"
                    name="entity"
                    options={entities}
                    value=""
                  />
                  <Select
                    formHook={importDataFormHook}
                    label="Import mode"
                    name="mode"
                    options={importMode}
                    value=""
                  />

                  <FormButtonWrapper>
                    <Button
                      type="submit"
                      className={classes.saveButton}
                      data-test-id="export-button"
                    >
                      Import
                    </Button>
                  </FormButtonWrapper>
                </form>
              )}
            </Section>
          </Col>
          <Col xs={6}>
            <Section>
              <h3>Export</h3>
              <form onSubmit={exportDataFormHook.handleSubmit(exportData)}>
                <Select
                  formHook={exportDataFormHook}
                  label="Entity to be exported"
                  name="entity"
                  options={entities}
                  value=""
                />

                <FormButtonWrapper>
                  <Button type="submit" className={classes.saveButton} data-test-id="export-button">
                    Export
                  </Button>
                </FormButtonWrapper>
              </form>
            </Section>
          </Col>
        </Row>
      </Paper>
    </>
  )
}

export default ImportExport
