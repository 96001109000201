import React, { useEffect, useCallback } from 'react'
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import packageJson from '../../package.json'
import { useSelector, useDispatch } from 'react-redux'

import Login from './components/pages/login'
import Menu from 'app/components/common/menu'
import Spinner from 'app/components/common/spinner'

import Customer from 'app/components/pages/customer'
import Loaner from 'app/components/pages/loaner'
import Agreement from 'app/components/pages/agreement'
import Search from 'app/components/pages/search'
import NotFound from 'app/components/pages/404'
import Settings from 'app/components/pages/settings'
import ImportExport from 'app/components/pages/importExport'
import Register from 'app/components/pages/register'

import { AUTH } from './constants'
import { getSettings, userLogout, verifyToken } from './store/actions'
import { getAuthentication } from './store/selector'

import { AppWrap, Main, Version } from 'app/styles/globalStyles'

const settings = {
  company: { logo: '' }
}

const users = {
  currentUser: { userAvatar: '' }
}

export const TOKEN = 'cr-auth-token'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const path = useLocation()

  const auth = useSelector(getAuthentication)
  const logout = useCallback(() => {
    window.sessionStorage.removeItem(AUTH.TOKEN_NAME)
    dispatch(userLogout())
    history.push('/login')
  }, [])

  useEffect(() => {
    dispatch(verifyToken())
  }, [])

  useEffect(() => {
    if (auth) dispatch(getSettings())
  }, [auth])

  if (path.pathname === '/register') {
    return (
      <>
        <Register />
        <Version>{`V${packageJson.version}`}</Version>
      </>
    )
  }

  if (!auth) {
    return (
      <>
        <Login />
        <Version>{`V${packageJson.version}`}</Version>
      </>
    )
  }

  return (
    <AppWrap>
      <Menu
        companyLogo={settings?.company?.logo}
        userAvatar={users.currentUser.userAvatar}
        logout={logout}
      />
      <Main>
        <Switch>
          <Route path="/" exact component={Agreement} />
          <Route path="/customer" exact component={Customer} />
          <Route path="/loaner" exact component={Loaner} />
          <Route path="/agreement" exact component={Agreement} />
          <Route path="/search" exact component={Search} />
          <Route path="/settings" exact component={Settings} />
          <Route path="/import-export" exact component={ImportExport} />
          {/* <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/user" exact component={User} />
          <Route path="/" exact component={Dashboard} /> */}
          <Redirect from="/login" to="/" />
          <Route path="/:page" exact component={NotFound} />
        </Switch>
        <Version>{`V${packageJson.version}`}</Version>
      </Main>
      {/* <Message
        type={message.type}
        open={!!message.type}
        autoHideDuration={MESSAGE.DURATION}
        message={message.text}
        onClose={clearMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      /> */}
    </AppWrap>
  )
}

export default App
