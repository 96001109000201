import React from 'react'

import Paper from '@material-ui/core/Paper'

import RegisterForm from 'app/components/forms/register'

import { pageStyles } from 'app/styles/page.styles'
import { LoginMain } from 'app/styles/globalStyles'

const Register = () => {
  const classes = pageStyles()

  return (
    <LoginMain>
      <Paper className={classes.loginWrap}>
        <h1>Register</h1>
        <RegisterForm />
      </Paper>
    </LoginMain>
  )
}

export default Register
