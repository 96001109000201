import { TAuthParam, TUserData } from 'types/auth.types'
import { call, put, takeEvery } from '@redux-saga/core/effects'
import toastr from 'Toastr.config'

import { setCurrentUser, verifyTokenSuccess, enableLoading, disableLoading } from '../actions'
import { getCurrentUser, resetPassword, verifyToken } from 'api/auth.api'

import { AUTH, SETTINGS_MESSAGE } from 'app/constants'

export default function* watchAuthSaga() {
  yield takeEvery(AUTH.VERIFY, handleverifyToken)
  yield takeEvery(AUTH.RESET, handleResetCurrentUserPassword)
}

function* handleverifyToken() {
  try {
    yield put(enableLoading())
    const auth: boolean = yield call(verifyToken)
    if (auth) {
      const user: TUserData = yield call(getCurrentUser)
      yield put(setCurrentUser(user))

      yield put(verifyTokenSuccess())
      yield put(disableLoading())
    } else throw new Error('Authentication failed')
  } catch (error) {
    // set error
    yield put(disableLoading())
  }
}

function* handleResetCurrentUserPassword(param: TAuthParam) {
  try {
    yield put(enableLoading())
    yield call(
      resetPassword,
      param.username,
      param.password.currentPassword,
      param.password.newPassword
    )
    yield put(disableLoading())
    yield toastr.success(SETTINGS_MESSAGE.RESET_PWD_SUCCESS)
  } catch (error) {
    yield put(disableLoading())
    yield toastr.error(SETTINGS_MESSAGE.RESET_PWD_FAILED)
    // set error
  }
}
