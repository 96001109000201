import styled from 'styled-components'
import { colour } from 'app/styles/globalStyles'

const CommentRow = styled.div`
  & > span:first-child {
    color: ${colour.tertiary};
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 5px;
    padding-top: 5px;
    display: block;
  }
  & > span:last-child {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
  }
`

const CommentHistoryHeading = styled.strong`
  position: sticky;
  top: -20px;
  background: white;
  display: block;
  padding: 15px 0;
`

export { CommentRow, CommentHistoryHeading }
