import { TCompanyData, TSettingsData } from 'types/settings.types'
import { SETTINGS } from 'app/constants'

export const getSettings = () => ({
  type: SETTINGS.GET
})

export const setSettings = (settings: TSettingsData) => ({
  type: SETTINGS.GET_SUCCESS,
  settings
})

export const updateCompany = (company: TCompanyData) => ({
  type: SETTINGS.UPDATE_COMPANY,
  company
})

export const updateCompanySuccess = (company: TCompanyData) => ({
  type: SETTINGS.UPDATE_COMPANY_SUCCESS,
  company
})
