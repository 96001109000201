import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide, { SlideProps } from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'

import { DialogContent, DialogActions } from './confirmDialog.styles'
import {TConfirmDailog} from './confirmDialog.types'

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => <Slide direction="down" ref={ref} {...props} />)

const ConfirmDialog:React.FC<TConfirmDailog> = ({
  children,
  cancelLabel,
  cancelAction,
  confirmLabel,
  confirmAction,
  isOpen
}) => {
  return (
    <Dialog
      data-test-id="confirm-dialog-background"
      open={isOpen}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => cancelAction()} color="primary" data-test-id='dialog-cancel-button'>
          {cancelLabel}
        </Button>
        <Button onClick={() => confirmAction()} color="secondary" data-test-id='dialog-confirm-button'>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
