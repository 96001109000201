import { TState } from 'types/state.types'

export const getLoadingState = (state: TState) => state.loading
export const getAllCustomers = (state: TState) => state.customers
export const getAllLoaners = (state: TState) => state.loaners
export const getAllAgreements = (state: TState) => state.agreements
export const getAuthentication = (state: TState) => state.authenticate
export const getSettingsSelector = (state: TState) => state.settings
export const getCompanyProfileSelector = (state: TState) => state.settings.companyProfile
export const getCurrentUserSelector = (state: TState) => state.currentUser
export const getImportFileInfo = (state: TState) => state.importFileInfo
