import AuthFirebaseAPI from './firestore/authFirestoreAPI'
import { TCredentials, TRegister, TUserData } from 'types/auth.types'

/**
 * Authenticate user - Login
 * @param {Object} credentials
 * @returns {boolean}
 */
export const login = async (credentials: TCredentials) => {
  return await AuthFirebaseAPI.login(credentials)
}

/**
 * Authenticate user - Registration
 * @param {Object} user
 */
export const register = async (user: TRegister) => {
  await AuthFirebaseAPI.register(user)
}

/**
 * Authenticate user - Logout
 */
export const logout = async () => {
  await AuthFirebaseAPI.logout()
}

/**
 * Authenticate user - Verify token
 * @returns boolean
 */
export const verifyToken = async (): Promise<boolean> => {
  return await AuthFirebaseAPI.verifyToken()
}

/**
 * Get current authenticated user
 * @returns {Object}
 */
export const getCurrentUser = async (): Promise<TUserData> => {
  return await AuthFirebaseAPI.getCurrentUser()
}

/**
 * Update or create current user
 * @param {Object} user
 */
export const updateCurrentUser = async (user: TUserData): Promise<void> => {
  await AuthFirebaseAPI.updateCurrentUser(user)
}

/**
 * Update current user password
 * @param {string} username
 * @param {string} currentPassword
 * @param {string} newPassword
 */
export const resetPassword = async (
  username: string,
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  await AuthFirebaseAPI.resetPassword(username, currentPassword, newPassword)
}
