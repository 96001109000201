import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import Button from '@material-ui/core/Button'

import Field from 'app/components/common/formElement/Field'
import Select, { TSelectOption } from 'app/components/common/formElement/Select'

import { createLoaner, updateLoaner } from 'app/store/actions'
import { getLoadingState } from 'app/store/selector'

import { TLoanerFormProps } from './loanerForm.types'
import { TLoanerData } from 'types/loaner.types'

import { FormButtonWrapper } from 'app/styles/form.styles'
import { buttonStyle } from 'app/styles/button.styles'

const transmissionOptions: TSelectOption[] = [
  { label: 'Please select ...', value: '' },
  { label: 'Automatic', value: 'Automatic' },
  { label: 'Manual', value: 'Manual' },
  { label: 'Tiptronic', value: 'Tiptronic' }
]

const LoanerForm: React.FC<TLoanerFormProps> = ({ loaner, closeFlyinWindow }) => {
  const dispatch = useDispatch()
  const classes = buttonStyle()
  const formHook = useForm()
  const loading = useSelector(getLoadingState)

  const onSubmit = (data: TLoanerData) => {
    const dataToSave: TLoanerData = loaner ? { ...loaner, ...data } : { ...data }
    const action = loaner ? updateLoaner : createLoaner
    dispatch(action(dataToSave))
    closeFlyinWindow(null)
  }

  return (
    <>
      {/* {(updateAction.loading || createAction.loading) && <Spinner />} */}
      <form onSubmit={formHook.handleSubmit(onSubmit)} data-test-id="loaner-form">
        <Field
          label="Rego"
          name="rego"
          value={loaner?.rego}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Make"
          name="make"
          value={loaner?.make}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Model"
          name="model"
          value={loaner?.model}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Year"
          name="year"
          value={loaner?.year}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Colour"
          name="colour"
          value={loaner?.colour}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Select
          label="Transmission"
          name="transmission"
          value={loaner?.transmission}
          options={transmissionOptions}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <FormButtonWrapper>
          <Button
            type="submit"
            className={classes.saveButton}
            data-test-id="loaner-submit-button"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            onClick={() => closeFlyinWindow(null)}
            className={classes.cancelButton}
            data-test-id="loaner-cancel-button"
            disabled={loading}
          >
            Cancel
          </Button>
        </FormButtonWrapper>
      </form>
    </>
  )
}

export default LoanerForm
