import styled from 'styled-components'
import { Snackbar } from '@material-ui/core'

export const colour = {
  blue: 'rgb(25, 118, 210)',
  blueHover: 'rgba(25, 118, 210, 0.8)',
  blueDisabled: 'rgba(25, 118, 210, 0.5)',
  secondary: 'rgb(43, 69, 112)',
  tertiary: 'rgb(134, 189, 226)',
  shadow: 'rgb(205, 210, 218)',
  light: 'rgb(110, 132, 163)',
  dark: 'rgb(70, 55, 48)',
  lightGrey: 'rgb(245, 248, 250)',
  alert: 'rgb(220, 0, 78)',
  alertHover: 'rgb(220, 0, 78, 0.8)',
  alertDisabled: 'rgb(220, 0, 78, 0.5)',
  success: 'rgba(76, 175, 80, 1)',
  fail: 'rgba(255, 0, 0, 0.6)',
  warning: 'rgba(255, 152, 0, 1)'
}

export const AppWrap = styled.div`
  display: flex;
  background-color: ${colour.lightGrey};
`

export const Main = styled.main`
  flex-grow: 1;
  min-height: 100vh;

  h1 {
    font-size: 30px;
    color: ${colour.light};
  }
`

export const LoginMain = styled(Main)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Loader = styled.img`
  width: 200px;
`

export const LoaderWrap = styled.div`
  background-color: ${colour.light};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`

export const Version = styled.div`
  position: absolute;
  text-align: right;
  padding: 10px 40px;
  color: #ccc;
  font-size: 14px;
  right: 10px;
  bottom: 10px;
  z-index: 99999;
`

export const Message = styled(Snackbar)<{ type: string }>`
  & > .MuiSnackbarContent-root {
    background-color: ${({ type }: { type: string }) => {
      if (type === 'success') return colour.success
      if (type === 'error') return colour.alert
      if (type === 'warning') return colour.warning
    }};
  }
`
