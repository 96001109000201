import React, { useState, useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import SelectWithSearch, {
  TSelectWithSearchOption
} from 'app/components/common/formElement/SelectWithSearch'

import { getAllLoaners } from 'app/store/selector'
import { getLoaners } from 'app/store/actions'

import { buttonStyle } from 'app/styles/button.styles'
import { FormButtonWrapper } from 'app/styles/form.styles'

import { TLoaner, TLoanerDetails } from './loanerDetails.types'
import { TLoanerData } from 'types/loaner.types'

const LoanerDetails: React.FC<TLoanerDetails> = ({ handleBack, handleNext }) => {
  const dispatch = useDispatch()
  const formHook = useForm()
  const [loaner, setLoaner] = useState<TLoaner>()
  const classes = buttonStyle()

  const loanerRaw = useSelector(getAllLoaners)

  useEffect(() => {
    if (loanerRaw.length === 0) dispatch(getLoaners())
  }, [])
  const loaners = useMemo(
    () =>
      loanerRaw.reduce((loaners: { label: string; value: any }[], loaner: TLoanerData) => {
        const { id, availability, ...rest } = loaner
        if (availability)
          loaners.push({
            label: `${loaner.make} ${loaner.model} -- ${loaner.rego}`,
            value: { ...rest, loanerId: id }
          })
        return loaners
      }, []),
    [loanerRaw]
  )

  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <h3>Loaner details</h3>
              <SelectWithSearch
                label="Search Lonaer"
                name="loaner"
                value=""
                validationRules={{ required: true }}
                formHook={formHook}
                options={loaners}
                onSelect={(loaner: TSelectWithSearchOption) => {
                  setLoaner(loaner.value)
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Make</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>Rego</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loaner && (
            <TableRow>
              <TableCell>{loaner?.make}</TableCell>
              <TableCell>{loaner?.model}</TableCell>
              <TableCell>{loaner?.rego}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={3}>
              <FormButtonWrapper fullWidth>
                <Button className={classes.previousButton} onClick={handleBack}>
                  Back
                </Button>
                <Button
                  data-test-id="next-button"
                  className={classes.saveButton}
                  disabled={!loaner}
                  onClick={() => handleNext(loaner!)}
                >
                  Next
                </Button>
              </FormButtonWrapper>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
export default LoanerDetails
