import SettingsFirestoreAPI from './firestore/settingsFirestoreAPI'
import { TCompanyData, TSettingsData } from 'types/settings.types'
/**
 * Update or create company profile
 * @param {Object} companyProfile
 */
export const updateCompanyProfile = async (companyProfile: TCompanyData): Promise<void> => {
  await SettingsFirestoreAPI.updateCompanyProfile(companyProfile)
}

/**
 * Get settings data
 * @returns {Object}
 */
export const getSettingsData = async (): Promise<TSettingsData> => {
  return await SettingsFirestoreAPI.getSettingsData()
}
