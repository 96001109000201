import { all } from 'redux-saga/effects'
import customerSaga from './customer.saga'
import settingsSaga from './settings.saga'
import userSaga from './user.saga'
import authSaga from './auth.saga'
import loanerSaga from './loaner.saga'
import agreementSaga from './agreement.saga'
import importExportDataSaga from './importExport.saga'

export default function* rootSaga() {
  yield all([
    customerSaga(),
    settingsSaga(),
    userSaga(),
    authSaga(),
    loanerSaga(),
    agreementSaga(),
    importExportDataSaga()
  ])
}
