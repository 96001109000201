import React, { useState, useCallback } from 'react'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'

import { PopoverContent } from './popoverOptions.style'
import {TPopoverOptions} from './popoverOptions.types'

const PopoverOptions:React.FC<TPopoverOptions> = ({ id, children, Trigger }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  
  const handleClick = useCallback((event: any)=> {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }, [open])

  const onClose = useCallback(() => setOpen(false), []) 

  return (
    <>
      <IconButton
        size="small"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        data-test-id={`${id}-trigger`}
      >
        <Trigger />
      </IconButton>

      <Popover
        data-test-id={`${id}-options`}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <PopoverContent onClick={handleClick}>{children}</PopoverContent>
      </Popover>
    </>
  )
}

export default PopoverOptions
