import { IImporter, TImportMode } from 'types/importExport.types'

class Importer implements IImporter<any> {
  protected importMode: TImportMode

  constructor(importMode: TImportMode) {
    this.importMode = importMode || ''
  }

  readFile(): Promise<void | any[]> {
    throw new Error('Method not implemented.')
  }

  import(data: any[]): void {
    throw new Error('Method not implemented.')
  }
}

export default Importer
