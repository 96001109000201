import { CUSTOMER } from 'app/constants'
import { TCustomerData, TGetAllCustomerAction } from 'types/customer.types'

export const customerReducer = (state: TCustomerData[] = [], action: TGetAllCustomerAction) => {
  if (action.type === CUSTOMER.GET_ALL_SUCCESS) return [...state, ...action.customers!]

  if (action.type === CUSTOMER.CREATE_SUCCESS) return [...state, action.customer!]

  if (action.type === CUSTOMER.UPDATE_SUCCESS) {
    return state.map((customer: TCustomerData) => {
      if (customer.id === action.customer?.id) return action.customer
      return customer
    })
  }

  if (action.type === CUSTOMER.COMMENT_SUCCESS) {
    return state.map((customer: TCustomerData) => {
      if (customer.id === action.customerId!)
        return { ...customer, comments: [action.comment, ...customer.comments!] }
      return customer
    })
  }
  return state
}
