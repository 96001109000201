import { takeEvery, call, put } from '@redux-saga/core/effects'
import toastr from 'Toastr.config'

import {
  createLoanerSuccess,
  updateLoanerSuccess,
  setLoaners,
  disableLoading,
  enableLoading,
  deleteLoanerSuccess
} from 'app/store/actions'

import { getAllLoaners, createLoaner, updateLoaner, deleteLoaner } from 'api/loaner.api'

import { LOANER, LOANER_MESSAGE } from 'app/constants'

import { TLoanerData, TLoanerParam } from 'types/loaner.types'

export default function* watchLoanerSaga() {
  yield takeEvery(LOANER.GET_ALL, handleGetLoaners)
  yield takeEvery(LOANER.CREATE, handleCreateLoaner)
  yield takeEvery(LOANER.UPDATE, handleUpdateLoaner)
  yield takeEvery(LOANER.DELETE, handleDeleteLoaner)
}

function* handleGetLoaners() {
  try {
    yield put(enableLoading())
    const loaners: TLoanerData[] = yield call(getAllLoaners)
    yield put(setLoaners(loaners))
    yield put(disableLoading())
  } catch (error) {
    // setError
    yield put(disableLoading())
    yield toastr.error(LOANER_MESSAGE.LOAD_FAILED)
  }
}

function* handleCreateLoaner(param: TLoanerParam) {
  try {
    yield put(enableLoading())
    const newLoaner: TLoanerData = yield call(createLoaner, param.loaner!)
    yield put(createLoanerSuccess(newLoaner))
    yield put(disableLoading())
    yield toastr.success(LOANER_MESSAGE.CREATE_SUCCESS)
  } catch (error) {
    // setError
    yield put(disableLoading())
    yield toastr.error(LOANER_MESSAGE.CREATE_FAILED)
  }
}

function* handleUpdateLoaner(param: TLoanerParam) {
  try {
    yield put(enableLoading())
    yield call(updateLoaner, param.loaner!)
    yield put(updateLoanerSuccess(param.loaner!))
    yield put(disableLoading())
    yield toastr.success(LOANER_MESSAGE.UPDATE_SUCCESS)
  } catch (error) {
    // set Error
    yield put(disableLoading())
    yield toastr.error(LOANER_MESSAGE.UPDATE_FAILED)
  }
}

function* handleDeleteLoaner(param: TLoanerParam) {
  try {
    yield put(enableLoading())
    yield call(deleteLoaner, param.loanerId!)
    yield put(deleteLoanerSuccess(param.loanerId!))
    yield put(disableLoading())
    yield toastr.success(LOANER_MESSAGE.DELETE_SUCCESS)
  } catch (error) {
    // setError
    yield put(disableLoading())
    yield toastr.error(LOANER_MESSAGE.DELETE_FAILED)
  }
}
