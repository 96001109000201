export const USER = {
  REGISTER: 'USER_REGISTER',
  LOGIN: 'USER_LOGIN',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGOUT: 'USER_LOGOUT',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  UPDATE: 'UPDATE_CURRENT_USER',
  UPDATE_SUCCESS: 'UPDATE_CURRENT_USER_SUCCESS'
}

export const AUTH = {
  VERIFY: 'VERIFY_TOKEN',
  VERIFY_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  TOKEN_NAME: 'anctech-iManager-ist',
  RESET: 'RESET_CURRENT_USER_PASSWORD'
}
