import { doc, getDocs, DocumentReference, setDoc } from '@firebase/firestore/lite'
import FBDB from 'db/firebase.config'
import { settingsCollection } from 'db/collections'

import FirestoreAPI from './FirestoreAPI'

import { TCompanyData, TSettingsData } from 'types/settings.types'

class SettingsFirestoreAPI extends FirestoreAPI {
  static async updateCompanyProfile(companyProfile: TCompanyData) {
    const companyRef = doc(
      FBDB,
      SettingsFirestoreAPI.prefixedTable('settings'),
      'companyProfile'
    ) as DocumentReference<TCompanyData>
    setDoc(companyRef, companyProfile)
  }

  static async getSettingsData(): Promise<TSettingsData> {
    try {
      const settingsSnapshot = await getDocs(settingsCollection)
      const settings = {} as TSettingsData
      settingsSnapshot.docs.forEach(setting => {
        settings[setting.id] = setting.data()
      })
      return settings
    } catch (error) {
      console.log(error)
      return { companyProfile: { companyName: '', abnAcn: '', phone: '', address: '' } }
    }
  }
}

export default SettingsFirestoreAPI
