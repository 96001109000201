import styled from 'styled-components'
import { colour } from 'app/styles/globalStyles'
import { TFlyinWindowWrapper } from './flyinWindow.types'

export const FlyinWindowWrapper = styled.div<TFlyinWindowWrapper>`
  width: ${({ maxWidth, isOpen }) => (isOpen ? maxWidth : 0)}px;
  background-color: white;
  transition: width 0.5s ease;
  right: 0;
  position: fixed;
  height: 100%;
  top: 0;
  box-shadow: -6px 0px 16px -13px rgba(181, 181, 181, 0.7);
  z-index: 99999;
`

export const FlyinWindowHeading = styled.h3`
  border-bottom: 5px solid ${colour.blueHover};
  color: ${colour.blueHover};
  padding: 20px 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > svg {
    color: ${colour.light};
    cursor: pointer;
  }
`

export const FlyinWindowContent = styled.div`
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 110px);
  position: relative;
`
