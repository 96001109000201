import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  QueryDocumentSnapshot,
  DocumentReference,
  updateDoc
} from 'firebase/firestore/lite'

import { agreementCollection } from 'db/collections'
import FBDB from 'db/firebase.config'

import FirestoreAPI from './FirestoreAPI'
import { mapAgreementDataToUI, now } from 'api/apiUtils'
import AgreementPDF from 'pdfTemplate/amgPDF'

import { TAgreementData } from 'types/agreement.types'
import { TCompanyData } from 'types/settings.types'
import { TLoanerData } from 'types/loaner.types'

class AgreementFirestoreAPI extends FirestoreAPI {
  static async getAllAgreements() {
    const agreementsSnapshot = await getDocs(agreementCollection)

    return (
      agreementsSnapshot.docs.map((agreement: QueryDocumentSnapshot<TAgreementData>) => ({
        id: agreement.id,
        ...mapAgreementDataToUI(agreement.data())
      })) || []
    )
  }

  static async createAgreement(agreement: TAgreementData) {
    const currentTime = now()

    // check if loaner associated to the agreement exists
    const loanerId = agreement.loanerId
    const loanerRef = doc(
      FBDB,
      AgreementFirestoreAPI.prefixedTable('loaners'),
      loanerId!
    ) as DocumentReference<TLoanerData>
    const loanerSnapshot = await getDoc(loanerRef)
    if (!loanerSnapshot.exists()) throw new Error('Loaner does not exist.')

    // check if loaner is availble
    if (!loanerSnapshot.data().availability) throw new Error('Loaner has been rented.')

    // set loaner availbility to false
    updateDoc(loanerRef, { availability: false })

    const newAgreementRef = await addDoc(agreementCollection, {
      ...agreement,
      createdAt: currentTime
    })

    // return new created agreement
    const newAgreement = await getDoc(newAgreementRef)
    return {
      id: newAgreement.id,
      ...mapAgreementDataToUI(newAgreement.data()!)
    }
  }

  static async importAgreement(agreement: TAgreementData) {
    const currentTime = now()
    const newAgreementRef = await addDoc(agreementCollection, {
      ...agreement,
      createdAt: agreement.createdAt ? agreement.createdAt : currentTime
    })

    // return new created customer
    const newAgreement = await getDoc(newAgreementRef)
    return {
      id: newAgreement.id,
      ...mapAgreementDataToUI(newAgreement.data()!)
    }
  }

  static async exportAgreement() {
    const agreementsSnapshot = await getDocs(agreementCollection)

    return (
      agreementsSnapshot.docs.map((agreement: QueryDocumentSnapshot<TAgreementData>) => ({
        id: agreement.id,
        ...agreement.data()
      })) || []
    )
  }

  static async viewAgreementPDF(agreementId: string) {
    const agreementRef = doc(
      FBDB,
      AgreementFirestoreAPI.prefixedTable('agreements'),
      agreementId
    ) as DocumentReference<TAgreementData>
    const agreementSnapshot = await getDoc(agreementRef)
    if (!agreementSnapshot.exists()) throw new Error('Agreement does not exist.')
    const companyRef = doc(
      FBDB,
      AgreementFirestoreAPI.prefixedTable('settings'),
      'companyProfile'
    ) as DocumentReference<TCompanyData>
    const companySnapshot = await getDoc(companyRef)
    if (!companySnapshot.exists()) throw new Error('Company profile is missing.')
    const pdf = new AgreementPDF(agreementSnapshot.data(), companySnapshot.data())
    await pdf.generate()
  }

  static async returnLoaner(agreementId: string) {
    // check if agreement exists
    const agreementRef = doc(
      FBDB,
      AgreementFirestoreAPI.prefixedTable('agreements'),
      agreementId
    ) as DocumentReference<TAgreementData>
    const agreementSnapshot = await getDoc(agreementRef)
    if (!agreementSnapshot.exists()) throw new Error('Agreement does not exist.')

    // check if loaner associated to the agreement exists
    const loanerId = agreementSnapshot.data().loanerId
    const loanerRef = doc(
      FBDB,
      AgreementFirestoreAPI.prefixedTable('loaners'),
      loanerId!
    ) as DocumentReference<TLoanerData>
    const loanerSnapshot = await getDoc(loanerRef)
    if (!loanerSnapshot.exists()) throw new Error('Loaner does not exist.')

    // set agreement returned date
    updateDoc(agreementRef, { returned: now() })

    // set loaner to be available
    updateDoc(loanerRef, { availability: true })

    return loanerId
  }
}

export default AgreementFirestoreAPI
