const mapAccounts = (account: string): string => {
  const accounts = {
    localhost: 'dev',
    'amg.anctech.com.au': 'amg',
    'amgv2.anctech.com.au': 'amg',
    'amgv3.anctech.com.au': 'amg'
  } as { [key: string]: string }

  return accounts[account] !== '' ? `${accounts[account]}_` : ''
}

export default mapAccounts
