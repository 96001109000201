import styled from 'styled-components'

export const DialogContent = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`

export const DialogActions = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`
