import styled from 'styled-components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { colour } from './globalStyles'
import { TButtonStyle } from './style.types'

const MAX_WIDTH = '800px'

export const buttonStyle = makeStyles(
  (): TButtonStyle => ({
    saveButton: {
      backgroundColor: colour.blueHover,
      borderRadius: '20px',
      padding: '5px 30px',
      color: 'white',

      '&:hover': {
        backgroundColor: colour.blue
      },

      '&[disabled]': {
        backgroundColor: colour.blueDisabled,
        color: 'white'
      }
    },
    cancelButton: {
      backgroundColor: colour.alertHover,
      borderRadius: '20px',
      padding: '5px 30px',
      color: 'white',

      '&:hover': {
        backgroundColor: colour.alert
      },

      '&[disabled]': {
        backgroundColor: colour.alertDisabled,
        color: 'white'
      }
    },
    previousButton: {
      backgroundColor: colour.shadow,
      borderRadius: '20px',
      padding: '5px 30px',
      marginBottom: '10px',
      color: 'black',

      '&:hover': {
        backgroundColor: colour.shadow
      }
    },
    link: {
      backgroundColor: 'rgba(0, 0, 0, 0)',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }
    },
    createButton: {
      backgroundColor: `${colour.blueHover} !important`,
      borderRadius: '30px !important',
      color: 'white !important',
      padding: '5px 20px 5px 15px !important',
      '&:hover': {
        backgroundColor: `${colour.blue} !important`
      }
    },
    optionsButton: {
      color: `${colour.dark} !important`,
      fontWeight: '400 !important'
    },
    deleteButton: {
      color: `${colour.alertHover} !important`
    }
  })
)

export const ButtonWrap = styled.div`
  display: flex;
  button {
    margin: 0 20px;
  }
`
