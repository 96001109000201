import { takeEvery, call, put } from '@redux-saga/core/effects'
import toastr from 'Toastr.config'

import {
  createCustomerSuccess,
  updateCustomerSuccess,
  setCustomers,
  disableLoading,
  enableLoading,
  commentCustomerSuccess
} from 'app/store/actions'

import { getAllCustomers, createCustomer, updateCustomer, commentCustomer } from 'api/customer.api'

import { CUSTOMER, CUSTOMER_MESSAGE } from 'app/constants'

import { TCustomerData, TCustomerParam } from 'types/customer.types'

export default function* watchCustomerSaga() {
  yield takeEvery(CUSTOMER.GET_ALL, handleGetCustomers)
  yield takeEvery(CUSTOMER.CREATE, handleCreateCustomer)
  yield takeEvery(CUSTOMER.UPDATE, handleUpdateCustomer)
  yield takeEvery(CUSTOMER.COMMENT, handleCommentCustomer)
}

function* handleGetCustomers() {
  try {
    yield put(enableLoading())
    const customers: TCustomerData[] = yield call(getAllCustomers)
    yield put(setCustomers(customers))
    yield put(disableLoading())
  } catch (error) {
    // setError
    yield put(disableLoading())
    yield toastr.error(CUSTOMER_MESSAGE.LOAD_FAILED)
  }
}

function* handleCreateCustomer(param: TCustomerParam) {
  try {
    yield put(enableLoading())
    const newCustomer: TCustomerData = yield call(createCustomer, param.customer!)
    yield put(createCustomerSuccess(newCustomer))
    yield put(disableLoading())
    yield toastr.success(CUSTOMER_MESSAGE.CREATE_SUCCESS)
  } catch (error) {
    // setError
    yield put(disableLoading())
    yield toastr.error(CUSTOMER_MESSAGE.CREATE_FAILED)
  }
}

function* handleUpdateCustomer(param: TCustomerParam) {
  try {
    yield put(enableLoading())
    yield call(updateCustomer, param.customer!)
    yield put(updateCustomerSuccess(param.customer!))
    yield put(disableLoading())
    yield toastr.success(CUSTOMER_MESSAGE.UPDATE_SUCCESS)
  } catch (error) {
    // set Error
    yield put(disableLoading())
    yield toastr.error(CUSTOMER_MESSAGE.UPDATE_FAILED)
  }
}

function* handleCommentCustomer(param: TCustomerParam) {
  try {
    yield put(enableLoading())
    yield call(commentCustomer, param.customerId!, param.comment!)
    yield put(commentCustomerSuccess(param.customerId!, param.comment!))
    yield put(disableLoading())
    yield toastr.success(CUSTOMER_MESSAGE.COMMENT_SUCCESS)
  } catch (error) {
    // set Error
    yield put(disableLoading())
    yield toastr.error(CUSTOMER_MESSAGE.COMMENT_FAILED)
  }
}
