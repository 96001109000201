export const termsAndCond = [
  'The courtesy vehicle described above is made available to the customer as a bailee at will, subject to the following condition:',
  '1. The all running expenses(including fuel) will be met by the customer.',
  '2. The customer is responsible for any damages mechanical or otherwise caused to the vehicle loaned.',
  '3. That any damage done to the vehicle, including damage to body paint trim and/or mechanical componets as a result of accident or collision will be reported, as soon as possible to the provider.',
  '4. The customer understands that he or she is reliable and agrees to meet the costs of any repairs to the vehicle arising from accident or collision where that cost is less that the insurance excess of AUD 2,000 as shown above. Where the cost of the repairs exceeds the insurance exess detailed above, the customer agrees to meet the whole of the insurance excess of AUD 2,000 detailed above.',
  "5. If the customer is involved in an accident and insurer of the loaned vehicle denies paying the claim on the basis that the customer was under the influence of alcohol or drugs at the time of the accident then the customer will meet at of the costs associated with the accident including third party's costs",
  '6. The customer undertakes that he/she will comply with all road traffic laws and rules when driving the loaned vehicle.',
  '7. The customer agrees to meet the cost of any traffic infringements incurred whilist the vehicles is in his or her possession and agrees to pay all fines imposed as a result thereof',
  '8. The customer will not allow any other person to drive the loaned vehicle wihtout obtaining a prior written approval from <company name>.',
  '9. The customer will meet the cost of restoring the vehicle to reasonable condition if the vehicle is returned in an unclean condition.',
  '10. In the event the vehicle is not returned to the provider at or before the time specified above the customer acknowledges that he or she will pay the provider a fee for the hire of the vehicle for the excess period at the rate of $60 per day.'
]
