import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import Field from 'app/components/common/formElement/Field'
import DateField from 'app/components/common/formElement/Date'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import PopoverOptions from 'app/components/common/popoverOption'
import Spinner from 'app/components/common/spinner'

import { getAllAgreements, getLoadingState } from 'app/store/selector'
import { DATE_TO_DB } from 'app/constants'
import { getAgreements, viewAgreement } from 'app/store/actions'

import { TAgreementData } from 'types/agreement.types'

import { SearchWrap } from 'app/styles/form.styles'
import { PageHeader, pageStyles } from 'app/styles/page.styles'
import { buttonStyle, ButtonWrap } from 'app/styles/button.styles'

const Search = () => {
  const dispatch = useDispatch()
  const formHook = useForm()
  const classes = { ...pageStyles(), ...buttonStyle() }
  const [page, setPage] = useState(0)
  const [agreements, setAgreements] = useState<TAgreementData[]>([])
  const rowPerPage = 10
  const regoVal = formHook.watch('rego', '')
  const penaltyDateVal = formHook.watch('penaltyDate', '')

  const loading = useSelector(getLoadingState)
  const agreementList = useSelector(getAllAgreements)

  useEffect(() => {
    if (agreementList.length === 0) dispatch(getAgreements())
  }, [])

  if (loading) {
    return <Spinner />
  }

  const getDate = (date: string) => {
    const d = date.split(' ')[0]
    return moment(d, DATE_TO_DB)
  }

  const doSearch = () => {
    setAgreements(
      agreementList.filter((agreement: TAgreementData) => {
        const DATE_FORMAT = 'DD/MM/YYYY'
        const penaltyDate = moment(penaltyDateVal, DATE_FORMAT)

        return (
          agreement.rego!.includes(regoVal) &&
          getDate(agreement.createdAt!) <= penaltyDate &&
          (!agreement.returned || getDate(agreement.returned!) >= penaltyDate)
        )
      })
    )
  }

  const cancelSearch = () => {
    setAgreements([])
    formHook.setValue('rego', '')
    formHook.setValue('penaltyDate', '')
  }
  return (
    <>
      <PageHeader>
        <h1>Search Penalty</h1>
      </PageHeader>
      <Paper className={classes.paper}>
        <SearchWrap onSubmit={formHook.handleSubmit(doSearch)}>
          <Field
            label="REGO:"
            name="rego"
            value=""
            validationRules={{ required: true }}
            formHook={formHook}
            hideError
          />
          <DateField
            label="FINE DATE:"
            name="penaltyDate"
            value=""
            validationRules={{ required: true }}
            formHook={formHook}
            hideError
          />
          <ButtonWrap>
            <Button type="submit" className={classes.saveButton} startIcon={<SearchOutlinedIcon />}>
              Search
            </Button>
            {!(regoVal === '' && penaltyDateVal === '') && (
              <Button
                data-test-id="clear-filter-button"
                className={classes.cancelButton}
                startIcon={<ClearOutlinedIcon />}
                onClick={cancelSearch}
              >
                Clear
              </Button>
            )}
          </ButtonWrap>
        </SearchWrap>
      </Paper>
      <br />
      <Paper className={classes.paper}>
        <Table aria-label="Agreement list">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Driver Licence</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Make</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Rego</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agreements
              .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
              .map((agreement: TAgreementData) => (
                <TableRow key={agreement.id}>
                  <TableCell></TableCell>
                  <TableCell>
                    {agreement.lastname
                      ? `${agreement.lastname}, ${agreement.firstname}`
                      : `${agreement.firstname}`}
                  </TableCell>
                  <TableCell>{agreement.licence}</TableCell>
                  <TableCell>{agreement.phone}</TableCell>
                  <TableCell>{agreement.make}</TableCell>
                  <TableCell>{agreement.model}</TableCell>
                  <TableCell>{agreement.rego}</TableCell>
                  <TableCell align="right" className={classes.options}>
                    <PopoverOptions id={`${agreement.id!}`} Trigger={MoreVertIcon}>
                      <Button
                        onClick={() => {
                          dispatch(viewAgreement(agreement.id!))
                        }}
                        data-test-id="preview-agreement"
                        className={classes.optionsButton}
                      >
                        Preview
                      </Button>
                    </PopoverOptions>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={agreements.length}
          rowsPerPage={10}
          page={page}
          onPageChange={(e, num) => setPage(num)}
        />
      </Paper>
    </>
  )
}

export default Search
