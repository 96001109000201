import { LOANER } from 'app/constants'
import { TLoanerData, TGetAllLoanerAction } from 'types/loaner.types'

export const loanerReducer = (state: TLoanerData[] = [], action: TGetAllLoanerAction) => {
  if (action.type === LOANER.GET_ALL_SUCCESS) return [...state, ...action.loaners!]

  if (action.type === LOANER.CREATE_SUCCESS) return [...state, action.loaner!]

  if (action.type === LOANER.UPDATE_SUCCESS) {
    return state.map((loaner: TLoanerData) => {
      if (loaner.id === action.loaner?.id) return action.loaner
      return loaner
    })
  }

  if (action.type === LOANER.DELETE_SUCCESS)
    return state.filter((loaner: TLoanerData) => loaner.id !== action.loanerId!)

  if (action.type === LOANER.UPDATE_AVAILABILITY_SUCCESS) {
    return state.map((loaner: TLoanerData) => {
      if (loaner.id === action.loanerId!) return { ...loaner, availability: action.availability }
      return loaner
    })
  }

  return state
}
