import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import AgreementList from './agreementList'
import AgreementCreate from './agreementCreate'

import { PageHeader, pageStyles } from 'app/styles/page.styles'
import { buttonStyle } from 'app/styles/button.styles'

const Agreement: React.FC = () => {
  const classes = { ...pageStyles(), ...buttonStyle() }
  const [isCreate, setIsCreate] = useState(false)

  return (
    <>
      <PageHeader>
        <h1>Loaner Agreement</h1>
        {!isCreate ? (
          <Button
            onClick={() => {
              setIsCreate(true)
            }}
            className={classes.createButton}
            startIcon={<AddOutlinedIcon />}
            data-test-id="create-button"
          >
            Create
          </Button>
        ) : (
          <Button
            onClick={() => {
              setIsCreate(false)
            }}
            className={classes.cancelButton}
            startIcon={<CloseIcon />}
            data-test-id="cancel-button"
          >
            Cancel
          </Button>
        )}
      </PageHeader>
      {isCreate ? <AgreementCreate postCreate={() => setIsCreate(false)} /> : <AgreementList />}
    </>
  )
}

export default Agreement
