import find from 'lodash/find'

import Importer from './Importer'
import { createCustomer, updateCustomer } from 'api/customer.api'
import { excelDateToJSDate, isNull } from 'app/utils/importerUtils'

import { IImporter, TImportMode } from 'types/importExport.types'
import { TCustomerData } from 'types/customer.types'
import { EDateFormat } from 'types/importExport.types'

class CustomerImporter extends Importer implements IImporter<TCustomerData> {
  private customers: TCustomerData[] = []

  constructor(importMode: TImportMode) {
    super(importMode)
  }

  mapCustomer(data: any): TCustomerData {
    const {
      licence,
      firstname,
      lastname,
      name,
      dob,
      gender,
      phone,
      email,
      address,
      createdAt,
      updatedAt,
      deletedAt
    } = data
    return {
      licence,
      firstname: firstname ? firstname : name,
      lastname: lastname ? lastname : '',
      dob: excelDateToJSDate(dob, EDateFormat.DATE),
      gender,
      phone,
      email,
      address,
      comments: [],
      createdAt: excelDateToJSDate(createdAt, EDateFormat.DATETIME),
      updatedAt: excelDateToJSDate(updatedAt, EDateFormat.DATETIME),
      deletedAt: !isNull(deletedAt) ? excelDateToJSDate(deletedAt, EDateFormat.DATETIME) : null
    }
  }

  import(data: any[]): void {
    data.forEach(async (customer: any) => {
      // checking if customer exists
      const foundCustomer = find(this.customers, { licence: customer.licence })
      if (foundCustomer === undefined) {
        const newCustomer = await createCustomer(this.mapCustomer(customer))
        this.customers.push(newCustomer)
      } else {
        if (this.importMode === 'override') {
          await updateCustomer(this.mapCustomer({ ...foundCustomer, ...customer }))
        }

        if (this.importMode === 'duplicate') {
          const newCustomer = await createCustomer(this.mapCustomer(customer))
          this.customers.push(newCustomer)
        }
      }
      setTimeout(() => {}, 5000)
    })
  }
}

export default CustomerImporter
