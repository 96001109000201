import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import PopoverOptions from 'app/components/common/popoverOption'
import FlyinWindow from 'app/components/common/flyinWindow'
import LoanerForm from 'app/components/forms/loaner'
import ConfirmDialog from 'app/components/common/confirmDialog'
import Spinner from 'app/components/common/spinner'

import { CREATE_LOANER, EDIT_LOANER } from './loaner.constants'
import { getAllLoaners, getLoadingState } from 'app/store/selector'
import { deleteLoanerAction, getLoaners } from 'app/store/actions'

import { TLoanerProps, TLoanerData } from 'types/loaner.types'

import { PageHeader, pageStyles } from 'app/styles/page.styles'
import { buttonStyle } from 'app/styles/button.styles'

const Loaner: React.FC<TLoanerProps> = () => {
  const dispatch = useDispatch()
  const pageClasses = pageStyles()
  const buttonClasses = buttonStyle()
  const [formType, setFormType] = useState<string | null>(null)
  const [loaner, setLoaner] = useState({} as TLoanerData)
  const [isDelete, setIsDelete] = useState(false)
  const [page, setPage] = useState(0)
  const rowPerPage = 10

  const loaners = useSelector(getAllLoaners)
  const loading = useSelector(getLoadingState)
  useEffect(() => {
    if (loaners.length === 0) dispatch(getLoaners())
  }, [])

  if (loading) {
    return <Spinner />
  }

  const editLoanerPanel = (l: TLoanerData) => {
    setLoaner(l)
    setFormType(EDIT_LOANER)
  }

  const delLoaner = (l: TLoanerData) => {
    setLoaner(l)
    setIsDelete(true)
  }

  const confirmDel = () => {
    dispatch(deleteLoanerAction(loaner.id!))
    setIsDelete(false)
  }
  return (
    <>
      <PageHeader>
        <h1>Loaner</h1>
        <Button
          data-test-id="create-button"
          onClick={() => setFormType(CREATE_LOANER)}
          className={buttonClasses.createButton}
          startIcon={<AddOutlinedIcon />}
        >
          Create
        </Button>
      </PageHeader>
      <Paper className={pageClasses.paper}>
        <Table aria-label="Loaner list">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Rego</TableCell>
              <TableCell>Make</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Colour</TableCell>
              <TableCell>Transmission</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loaners
              .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
              .map((loaner: TLoanerData) => (
                <TableRow
                  key={loaner.id}
                  className={loaner.availability ? '' : pageClasses.unavailable}
                >
                  <TableCell></TableCell>
                  <TableCell>{loaner.rego}</TableCell>
                  <TableCell>{loaner.make}</TableCell>
                  <TableCell>{loaner.model}</TableCell>
                  <TableCell>{loaner.year}</TableCell>
                  <TableCell>{loaner.colour}</TableCell>
                  <TableCell>{loaner.transmission}</TableCell>
                  <TableCell align="right" className={pageClasses.options}>
                    <PopoverOptions id={loaner.id!} Trigger={MoreVertIcon}>
                      <Button
                        onClick={() => editLoanerPanel(loaner)}
                        data-test-id="edit-loaner"
                        className={buttonClasses.optionsButton}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => delLoaner(loaner)}
                        data-test-id="delete-loaner"
                        className={buttonClasses.deleteButton}
                      >
                        Delete
                      </Button>
                    </PopoverOptions>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={loaners.length}
          rowsPerPage={10}
          page={page}
          onPageChange={(e, num) => setPage(num)}
        />
      </Paper>
      <FlyinWindow
        isOpen={!!formType}
        onClose={() => setFormType(null)}
        maxWidth={600}
        heading={formType!}
      >
        {formType === CREATE_LOANER && <LoanerForm closeFlyinWindow={setFormType} />}
        {formType === EDIT_LOANER && <LoanerForm loaner={loaner} closeFlyinWindow={setFormType} />}
      </FlyinWindow>
      <ConfirmDialog
        isOpen={isDelete}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmAction={() => confirmDel()}
        cancelAction={() => setIsDelete(false)}
      >
        <p>
          {loaner && (
            <>
              <span>Do you want to delete </span>
              <strong>
                {loaner.make} {loaner.model} {loaner.rego} ?
              </strong>
            </>
          )}
        </p>
      </ConfirmDialog>
    </>
  )
}

export default Loaner
