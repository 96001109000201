import readXlsxFile from 'read-excel-file'
import writeXlsxFile, { SheetData } from 'write-excel-file'

import CustomerImporter from 'importers/customerImporter'
import LoanerImporter from 'importers/loanerImporter'
import AgreementImporter from 'importers/agreementImporter'

import { exportAgreement } from './agreement.api'
import { getAllCustomers } from './customer.api'
import { getAllLoaners } from './loaner.api'

import { FileUpload } from 'use-file-upload'
import { TImportFileInfo, TImportOpt } from 'types/importExport.types'
import { TCustomerData } from 'types/customer.types'
import { TLoanerData } from 'types/loaner.types'
import { TAgreementData } from 'types/agreement.types'

export const processFile = async (file: FileUpload): Promise<TImportFileInfo> => {
  const content = { headers: [], data: [] } as TImportFileInfo
  const fileInfo = await readXlsxFile(file.file)
  content.headers = fileInfo[0] as string[]
  fileInfo.forEach((data, index) => {
    if (index !== 0) {
      const row = {} as any
      content.headers.forEach((header, idx) => {
        row[header] = data[idx]
      })
      content.data.push(row)
    }
  })
  // return the first row of the spreadsheet - heading
  return content
}

export const importData = async (opt: TImportOpt, data: any[]): Promise<void> => {
  switch (opt.entity) {
    case 'Customers':
      const customerImporter = new CustomerImporter(opt.mode)
      await customerImporter.import(data)
      return
    case 'Loaners':
      const loanerImporter = new LoanerImporter(opt.mode)
      await loanerImporter.import(data)
      return
    case 'Agreements':
      const loaners = await getAllLoaners()
      const agreementImporter = new AgreementImporter(opt.mode, loaners)
      await agreementImporter.import(data)
      return
    default:
      console.log(`Entity ${opt.entity} is not a valid entity name`)
      return
  }
}

export const exportData = async (entity: string): Promise<void> => {
  switch (entity) {
    case 'Customers':
      const allCustomers = await getAllCustomers(true)
      return await writeXlsxFile(mapCustomerToExcel(allCustomers), { fileName: 'customers.xlsx' })
    case 'Loaners':
      const allLoaners = await getAllLoaners(true)
      return await writeXlsxFile(mapLoanerToExcel(allLoaners), { fileName: 'loaners.xlsx' })
    case 'Agreements':
      const allAgreements = await exportAgreement()
      return await writeXlsxFile(mapAgreementToExcel(allAgreements), {
        fileName: 'agreements.xlsx'
      })
    default:
      console.log(`Entity ${entity} is not a valid entity name`)
      return
  }
}

export const mapCustomerToExcel = (data: TCustomerData[]): SheetData => {
  const table: SheetData = [
    [
      { value: 'licence', fontWeight: 'bold' },
      { value: 'firstname', fontWeight: 'bold' },
      { value: 'lastname', fontWeight: 'bold' },
      { value: 'dob', fontWeight: 'bold' },
      { value: 'phone', fontWeight: 'bold' },
      { value: 'email', fontWeight: 'bold' },
      { value: 'gender', fontWeight: 'bold' },
      { value: 'address', fontWeight: 'bold' },
      { value: 'createdAt', fontWeight: 'bold' },
      { value: 'updateDAt', fontWeight: 'bold' },
      { value: 'deletedAt', fontWeight: 'bold' }
    ]
  ]
  data.forEach(customer => {
    table.push([
      { value: customer.licence },
      { value: customer.firstname },
      { value: customer.lastname },
      { value: customer.dob! },
      { value: customer.phone },
      { value: customer.email! },
      { value: customer.gender! },
      { value: customer.address },
      { value: customer.createdAt! },
      { value: customer.updatedAt! },
      { value: customer.deletedAt! }
    ])
  })

  return table
}

export const mapLoanerToExcel = (data: TLoanerData[]): SheetData => {
  const table: SheetData = [
    [
      { value: 'rego', fontWeight: 'bold' },
      { value: 'make', fontWeight: 'bold' },
      { value: 'model', fontWeight: 'bold' },
      { value: 'year', fontWeight: 'bold' },
      { value: 'transmission', fontWeight: 'bold' },
      { value: 'colour', fontWeight: 'bold' },
      { value: 'availability', fontWeight: 'bold' },
      { value: 'createdAt', fontWeight: 'bold' },
      { value: 'updateDAt', fontWeight: 'bold' },
      { value: 'deletedAt', fontWeight: 'bold' }
    ]
  ]
  data.forEach(loaner => {
    table.push([
      { value: loaner.rego },
      { value: loaner.make },
      { value: loaner.model },
      { value: loaner.year },
      { value: loaner.transmission },
      { value: loaner.colour! },
      { value: loaner.availability! },
      { value: loaner.createdAt! },
      { value: loaner.updatedAt! },
      { value: loaner.deletedAt! }
    ])
  })

  return table
}

export const mapAgreementToExcel = (data: TAgreementData[]): SheetData => {
  const table: SheetData = [
    [
      { value: 'licence', fontWeight: 'bold' },
      { value: 'firstname', fontWeight: 'bold' },
      { value: 'lastname', fontWeight: 'bold' },
      { value: 'dob', fontWeight: 'bold' },
      { value: 'phone', fontWeight: 'bold' },
      { value: 'email', fontWeight: 'bold' },
      { value: 'gender', fontWeight: 'bold' },
      { value: 'address', fontWeight: 'bold' },
      { value: 'rego', fontWeight: 'bold' },
      { value: 'make', fontWeight: 'bold' },
      { value: 'model', fontWeight: 'bold' },
      { value: 'year', fontWeight: 'bold' },
      { value: 'transmission', fontWeight: 'bold' },
      { value: 'colour', fontWeight: 'bold' },
      { value: 'mileage', fontWeight: 'bold' },
      { value: 'fuel', fontWeight: 'bold' },
      { value: 'signature', fontWeight: 'bold' },
      { value: 'returned', fontWeight: 'bold' },
      { value: 'loanerId', fontWeight: 'bold' },
      { value: 'createdAt', fontWeight: 'bold' }
    ]
  ]
  data.forEach(agreement => {
    table.push([
      { value: agreement.licence },
      { value: agreement.firstname },
      { value: agreement.lastname },
      { value: agreement.dob! },
      { value: agreement.phone },
      { value: agreement.email! },
      { value: agreement.gender! },
      { value: agreement.address },
      { value: agreement.rego },
      { value: agreement.make },
      { value: agreement.model },
      { value: agreement.year },
      { value: agreement.transmission },
      { value: agreement.colour! },
      { value: agreement.mileage! },
      { value: agreement.fuel! },
      { value: agreement.signature! },
      { value: agreement.returned! },
      { value: agreement.loanerId! },
      { value: agreement.createdAt! }
    ])
  })

  return table
}
