import moment from 'moment'
import { EDateFormat } from 'types/importExport.types'

/**
 * Convert excel dateValue to javascript date
 * @param {number} serial
 * @param {string} format
 * @returns {string}
 */
export const excelDateToJSDate = (serial: number, format: EDateFormat) => {
  var utc_days = Math.floor(serial - 25569)
  var utc_value = utc_days * 86400
  var date_info = new Date(utc_value * 1000)

  var fractional_day = serial - Math.floor(serial) + 0.0000001

  var total_seconds = Math.floor(86400 * fractional_day)

  var seconds = total_seconds % 60

  total_seconds -= seconds

  var hours = Math.floor(total_seconds / (60 * 60))
  var minutes = Math.floor(total_seconds / 60) % 60

  const date = new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  )
  return moment(date).format(format)
}

/**
 * check if value is null value
 * @param {sting | null} value
 * @returns {boolean}
 */
export const isNull = (value: string | number | null): boolean => {
  return (
    value === 'NULL' ||
    value === 'null' ||
    value === null ||
    value === '-' ||
    value === ' ' ||
    value === ''
  )
}

/**
 * check true value for '0', 'false', etc
 * @param value
 * @returns {boolean}
 */
export const isTrue = (value: string): boolean => {
  return value !== '0' && value !== 'false' && value !== 'FALSE' && value !== 'False' && !!value
}
