import { useCallback, RefObject, useEffect, useState } from 'react'

const useClickOutside = (ref: RefObject<HTMLDivElement>) => {
  const [isClickOutside, setIsClickOutside] = useState<boolean>()

  const handleClickOutside = useCallback(event => {
    setIsClickOutside(!!ref.current && !ref.current.contains(event.target))
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, isClickOutside])

  return { isClickOutside }
}

export default useClickOutside
