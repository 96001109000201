import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'

import Field from 'app/components/common/formElement/Field'
import Password from 'app/components/common/formElement/Password'

import { userLogin } from 'app/store/actions'

import { TLogin } from 'types/auth.types'

import { FormButtonWrapper } from 'app/styles/form.styles'
import { buttonStyle } from 'app/styles/button.styles'

const LoginForm = () => {
  const dispatch = useDispatch()
  const classes = buttonStyle()
  const formHook = useForm()

  const onSubmit = async (credentials: TLogin) => {
    dispatch(userLogin(credentials))
  }

  return (
    <>
      <form onSubmit={formHook.handleSubmit(onSubmit)} data-test-id="login-form">
        <Field
          label="Username"
          name="username"
          value=""
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Password
          label="Password"
          name="password"
          value=""
          validationRules={{ required: true }}
          formHook={formHook}
        />

        <FormButtonWrapper align="center">
          <Button type="submit" className={classes.saveButton} data-test-id="login-button">
            Login
          </Button>
        </FormButtonWrapper>
      </form>
    </>
  )
}

export default LoginForm
