import { doc, DocumentReference, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore/lite'
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  updatePassword
} from 'firebase/auth'
import jwt from 'jsonwebtoken'
import dotenv from 'dotenv'

import FBDB, { auth } from 'db/firebase.config'
import FirestoreAPI from './FirestoreAPI'

import { AUTH } from 'app/constants'

import { TCredentials, TRegister, TUserData } from 'types/auth.types'

dotenv.config()

class AuthFirebaseAPI extends FirestoreAPI {
  static async login(credentials: TCredentials) {
    const { username, password } = credentials
    try {
      const currentUser = await signInWithEmailAndPassword(auth, username, password)
      if (!currentUser.user.uid) return false
      // get user data and check status value
      const userData = (await getDoc(
        doc(FBDB, AuthFirebaseAPI.prefixedTable('users'), currentUser.user.uid)
      )) as DocumentSnapshot<TUserData>
      if (!userData.exists()) return false
      if (!userData.data()?.status) {
        await auth.signOut()
        return false
      }
      // generate token and store it in session
      const token = jwt.sign({ uid: currentUser.user.uid }, process.env.REACT_APP_SECRET_KEY!)
      window.sessionStorage.setItem(AUTH.TOKEN_NAME, token)

      return true
    } catch (error) {
      console.log(error)

      return false
    }
  }

  static async register(user: TRegister) {
    // create firebase auth user
    const userCredentials = await createUserWithEmailAndPassword(auth, user.email, user.password!)
    delete user.confirmPassword
    delete user.password

    // save other user information
    await setDoc(doc(FBDB, AuthFirebaseAPI.prefixedTable('users'), userCredentials.user.uid), {
      ...user,
      status: false
    })

    // signOut user once user is stored
    await auth.signOut()
  }

  static async logout() {
    await auth.signOut()
  }

  static async verifyToken(): Promise<boolean> {
    try {
      const token = window.sessionStorage.getItem(AUTH.TOKEN_NAME)
      if (!token) return false

      jwt.verify(token!, process.env.REACT_APP_SECRET_KEY!)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  static async getCurrentUser() {
    try {
      const isValidToken = await AuthFirebaseAPI.verifyToken()
      if (!isValidToken) return {} as TUserData

      const token = window.sessionStorage.getItem(AUTH.TOKEN_NAME)
      const user = jwt.decode(token!) as jwt.JwtPayload
      if (user?.uid) {
        const userData = (await getDoc(
          doc(FBDB, AuthFirebaseAPI.prefixedTable('users'), user.uid as string)
        )) as DocumentSnapshot<TUserData>
        if (!userData.exists()) throw new Error('User not found.')

        return { ...userData.data(), id: user.uid }
      }
      return {} as TUserData
    } catch (error) {
      console.log(error)
      return {} as TUserData
    }
  }

  /**
   * Update or create current user
   * @param {Object} user
   */
  static async updateCurrentUser(user: TUserData) {
    const { id, ...rest } = user
    const currentUserRef = doc(
      FBDB,
      AuthFirebaseAPI.prefixedTable('users'),
      id!
    ) as DocumentReference<TUserData>
    await setDoc(currentUserRef, rest)
  }

  /**
   * Update current user password
   * @param {string} username
   * @param {string} currentPassword
   * @param {string} newPassword
   */
  static async resetPassword(username: string, currentPassword: string, newPassword: string) {
    try {
      const currentUser = await signInWithEmailAndPassword(auth, username, currentPassword)
      if (!currentUser.user.uid) throw new Error('Current password is incorrect.')

      const authObj = getAuth()
      const user = authObj.currentUser
      await updatePassword(user!, newPassword)
    } catch (error) {
      console.log(error)
    }
  }
}

export default AuthFirebaseAPI
