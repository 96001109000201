import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { find } from 'lodash'
import { useForm } from 'react-hook-form'
import subYears from 'date-fns/subYears'

import Button from '@material-ui/core/Button'

import Field from 'app/components/common/formElement/Field'
import Select, { TSelectOption } from 'app/components/common/formElement/Select'
import DateField from 'app/components/common/formElement/Date'
import Email from 'app/components/common/formElement/Email'

import { createCustomer, updateCustomer } from 'app/store/actions'
import { getLoadingState } from 'app/store/selector'

import { TCustomerFormProps } from './customerForm.types'
import { TCustomerData } from 'types/customer.types'

import 'react-datepicker/dist/react-datepicker.min.css'
import { FormButtonWrapper } from 'app/styles/form.styles'
import { buttonStyle } from 'app/styles/button.styles'

const genderOptions: TSelectOption[] = [
  { label: 'Please select ...', value: '' },
  { label: 'Male', value: 'm' },
  { label: 'Female', value: 'f' },
  { label: 'Other', value: 'o' }
]

const CustomerForm: React.FC<TCustomerFormProps> = ({ customer, closeFlyinWindow }) => {
  const dispatch = useDispatch()
  const classes = buttonStyle()
  const formHook = useForm()
  const loading = useSelector(getLoadingState)

  useEffect(() => {
    const gender = find(genderOptions, { value: customer?.gender })
    formHook.setValue('gender', gender?.value)
  }, [customer])

  const onSubmit = (data: TCustomerData) => {
    const dataToSave: TCustomerData = customer ? { ...customer, ...data } : { ...data }
    const action = customer ? updateCustomer : createCustomer
    dispatch(action(dataToSave))
    closeFlyinWindow(null)
  }

  return (
    <>
      <form onSubmit={formHook.handleSubmit(onSubmit)} data-test-id="customer-form">
        <Field
          label="Licence"
          name="licence"
          value={customer?.licence}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="First name"
          name="firstname"
          value={customer?.firstname}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Last name"
          name="lastname"
          value={customer?.lastname}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <DateField
          label="Date of birth"
          name="dob"
          value={customer?.dob}
          maxDate={subYears(new Date(), 16)}
          formHook={formHook}
          validationRules={{ required: true }}
        />
        <Select
          label="Gender"
          name="gender"
          value={customer?.gender}
          options={genderOptions}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Mobile"
          name="phone"
          value={customer?.phone}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Email
          label="Email"
          name="email"
          value={customer?.email}
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Address"
          name="address"
          value={customer?.address}
          validationRules={{ required: true }}
          formHook={formHook}
        />

        <FormButtonWrapper>
          <Button
            type="submit"
            className={classes.saveButton}
            data-test-id="customer-submit-button"
            disabled={loading}
          >
            Save
          </Button>

          <Button
            onClick={() => closeFlyinWindow(null)}
            className={classes.cancelButton}
            data-test-id="customer-cancel-button"
            disabled={loading}
          >
            Cancel
          </Button>
        </FormButtonWrapper>
      </form>
    </>
  )
}

export default CustomerForm
