import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers: any =
    !!process.env && process.env.NODE_ENV === 'development' ? composeWithDevTools : compose
  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
  const store = createStore(rootReducer, enhancer)
  sagaMiddleware.run(rootSaga)
  return store
}

export default configureStore
