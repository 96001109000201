export const CUSTOMER_MESSAGE = {
  LOAD_FAILED:'Cannot load customers.',
  CREATE_SUCCESS: 'Create customer successfully.',
  CREATE_FAILED: 'Create customer failed.',
  UPDATE_SUCCESS: 'Update customer successfully.',
  UPDATE_FAILED: 'Update customer failed.',
  COMMENT_SUCCESS: 'Add comment successfully.',
  COMMENT_FAILED: 'Add comment failed.'
}

export const LOANER_MESSAGE = {
  LOAD_FAILED: 'Cannot load loaners.',
  CREATE_SUCCESS: 'Create loaner successfully.',
  CREATE_FAILED: 'Create loaner failed.',
  UPDATE_SUCCESS: 'Update loaner successfully.',
  UPDATE_FAILED: 'Update loaner failed.',
  DELETE_SUCCESS: 'Delete loaner successfully.',
  DELETE_FAILED: 'Delete loaner failed.'
}

export const AGREEMENT_MESSAGE = {
  LOAD_FAILED: 'Cannot load agreements.',
  CREATE_SUCCESS: 'Create agreement successfully.',
  CREATE_FAILED: 'Create agreement failed.',
  RETURN_SUCCESS: 'Loaner has been returned successfully.',
  RETURN_FAILED: 'Loaner has been returned failed.',
  VIEW_PDF_FAILED: 'Cannot open agreement.'
}

export const SETTINGS_MESSAGE = {
  LOAD_FAILED: 'Cannot load company profile.',
  UPDATE_COMPANY_SUCCESS: 'Update company profile successfully.',
  UPDATE_COMPANY_FAILED: 'Update company profile failed.',
  UPDATE_USER_SUCCESS: 'Update user profile successfully.',
  UPDATE_USER_FAILED: 'Update userprofile failed.',
  LOGIN_SUCCESS: 'User login successfully.',
  LOGIN_FAILED:  'User credentials are incorrect.',
  RESET_PWD_SUCCESS: 'Reset password successfully.',
  RESET_PWD_FAILED: 'Reset Password failed.'
}

export const IMPORT_EXPORT_MESSAGE = {
  PROCESS_FILE_SUCCESS: 'Process file successfully.',
  PROCESS_FILE_FAILED: 'Process file failed.',
  IMPORT_SUCCESS: 'Import data successfully.',
  IMPORT_FAILED: 'Import data failed.',
  EXPORT_SUCCESS: 'Export data successfully.',
  EXPORT_FAILED: 'Export data failed.'
}