import React, {useCallback} from 'react'

type ColProps = {
  xs?: number
  md?: number
  lg?: number
}

export const Row: React.FC = ({ children }) => {
  return <div className="row">{children}</div>
}


export const Col: React.FC<ColProps> = ({xs,md, lg, children}) => {
  const colClass = useCallback(() => {
    let className = 'col'
    if(xs) className += ` col-${xs}`
    if(md) className += ` col-${md}`
    if(lg) className += ` col-${lg}`
    return className
  }, [xs, md, lg])
  return <div className={colClass()}>{children}</div>
}