import React from 'react'

import { ValidationRules, FieldValues, UseFormMethods } from 'react-hook-form'
import { FormWrapper } from 'app/styles/form.styles'

declare type TFieldPorps = {
  label: string
  value: any
  name: string
  validationRules?: ValidationRules
  formHook: UseFormMethods<FieldValues>
  hideError?: boolean
}

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Email: React.FC<TFieldPorps> = ({
  label,
  value,
  name,
  validationRules,
  formHook,
  hideError
}) => {
  const { register, errors } = formHook
  return (
    <FormWrapper>
      <label htmlFor={name}>{label}</label>
      <input
        className={errors?.[name] ? 'field-error' : ''}
        data-test-id={`${name}_input`}
        name={name}
        id={name}
        type="text"
        defaultValue={value}
        ref={register({ ...validationRules, pattern: emailPattern })}
      />
      {!hideError && errors?.[name]?.type === 'pattern' && (
        <span data-test-id="error-message" className="error">
          {`${label} is invalid.`}
        </span>
      )}
      {!hideError && errors?.[name]?.type === 'required' && (
        <span data-test-id="error-message" className="error">
          {`${label} is required.`}
        </span>
      )}
    </FormWrapper>
  )
}

export default Email
