import {
  PDFDocument,
  StandardFonts,
  PDFPage,
  RGB,
  ColorTypes,
  layoutMultilineText,
  TextAlignment
} from 'pdf-lib'
import isArray from 'lodash/isArray'

import { termsAndCond } from './termsConditions'
import { TFont, TPosition } from 'types/pdf.types'
import { TAgreementData } from 'types/agreement.types'
import { TCompanyData } from 'types/settings.types'

const fuelMap = ['1/4', '1/2', '3/4', 'Full']
class AgreementPDF {
  agreement: TAgreementData
  company: TCompanyData
  pageMargin = 20
  sectionSpace = 50
  lineSpace = 30

  constructor(agreement: TAgreementData, CompanyProfile: TCompanyData) {
    this.agreement = agreement
    this.company = CompanyProfile
  }

  convertRgbToPercent(red: number, green: number, blue: number): RGB {
    const percentage = (wholeNum: number): number => wholeNum / 255
    return {
      type: ColorTypes.RGB,
      red: percentage(red),
      green: percentage(green),
      blue: percentage(blue)
    }
  }

  drawHeading(page: PDFPage, font: TFont) {
    const fontSize = 12
    const text = 'AGREEMENT TO USE A COURTESY VEHICLE'
    const textWidth = font.bold?.widthOfTextAtSize(text, fontSize)!
    const textHeight = font.bold?.heightAtSize(14)!
    const currentY = page.getHeight() - this.pageMargin - 50
    const reactangleHeight = 40
    const reactangleWidth = page.getWidth() - this.pageMargin * 2

    page.drawRectangle({
      x: this.pageMargin,
      y: currentY,
      width: reactangleWidth,
      height: reactangleHeight,
      color: this.convertRgbToPercent(232, 232, 232),
      borderColor: this.convertRgbToPercent(0, 0, 0),
      borderWidth: 0.5
    })
    page.drawText(text, {
      x: (reactangleWidth - textWidth) / 2,
      y: currentY + (reactangleHeight - textHeight) / 2,
      font: font.bold,
      size: fontSize
    })

    return { x: this.pageMargin, y: currentY }
  }

  agreementTable(page: PDFPage, font: TFont, position: TPosition) {
    const rowHeight = 70
    const cols = [180.82, 125, 125, 125]
    const colStart = [0, 180.82, 305.82, 430.32]
    const {
      firstname,
      lastname,
      phone,
      licence,
      dob,
      address,
      make,
      year,
      model,
      rego,
      colour,
      createdAt,
      returned,
      mileage,
      fuel,
      email
    } = this.agreement
    const table: any[] = [
      [
        { label: 'Name:', content: `${lastname}, ${firstname}` },
        { label: 'Phone number:', content: `${phone}` },
        { label: 'Driver licence:', content: `${licence}` },
        { label: 'Date of birth:', content: dob }
      ],
      [
        { label: 'Address:', content: address },
        { label: 'Loaner:', content: `${year} ${make} ${model}` },
        { label: 'Rego:', content: rego },
        { label: 'Colour:', content: colour }
      ],
      [
        { label: 'Email:', content: email },
        { label: 'Mileage:', content: `${mileage} km` },
        { label: 'Fuel:', content: fuel ? `${fuelMap[Number(fuel) - 1]}` : '' },
        [
          {
            label: 'Rent:',
            content: createdAt
          },
          {
            label: 'Returned:',
            content: returned ? returned : ''
          }
        ]
      ]
    ]

    // draw table grid
    for (let i = 0; i < table.length; i++) {
      for (let j = 0; j < table[i].length; j++) {
        try {
          page.drawRectangle({
            x: this.pageMargin + colStart[j],
            y: position.y - rowHeight * (i + 1),
            width: cols[j],
            height: rowHeight,
            borderColor: this.convertRgbToPercent(0, 0, 0),
            borderWidth: 0.5
          })

          const cellPostion = {
            x: this.pageMargin + colStart[j],
            y: position.y - rowHeight * (i + 1) + 50
          }

          this.fillCell(page, table[i][j], font, cellPostion, isArray(table[i][j]))
        } catch (error) {
          console.log(error)
        }
      }
    }

    return { x: this.pageMargin, y: position.y - rowHeight * 3 }
  }

  fillCell(page: PDFPage, data: any, font: TFont, position: TPosition, isdataArray: boolean) {
    const leftMargin = position.x + 5
    const fontSize = 11
    const lineHeight = 12
    if (isdataArray) {
      const contentHeight = 28
      for (let i = 0; i < data.length; i++) {
        page.drawText(data[i].label, {
          x: leftMargin,
          y: position.y - contentHeight * i,
          font: font.bold,
          size: fontSize
        })
        page.drawText(data[i].content, {
          x: leftMargin,
          y: position.y - contentHeight * i - lineHeight,
          size: fontSize,
          font: font.normal
        })
      }
    } else {
      page.drawText(data.label, {
        x: leftMargin,
        y: position.y,
        font: font.bold,
        size: fontSize
      })

      const contentText = layoutMultilineText(data.content, {
        alignment: TextAlignment.Left,
        font: font.normal!,
        fontSize: fontSize,
        bounds: { x: leftMargin, y: position.y + lineHeight, width: 170, height: 53 }
      })

      let currentY = position.y - 18
      for (let i = 0; i < contentText.lines.length; i++) {
        page.drawText(`${contentText.lines[i].text}`, {
          x: leftMargin,
          y: currentY,
          size: fontSize,
          font: font.normal
        })
        currentY = currentY - lineHeight
      }
    }
  }

  termsAndCondition(page: PDFPage, font: TFont, position: TPosition) {
    const fontSize = 10
    const lineHeight = 16
    let y = position.y - lineHeight
    for (let condition = 0; condition < termsAndCond.length; condition++) {
      const contentText = layoutMultilineText(
        termsAndCond[condition].replaceAll('<company name>', this.company.companyName),
        {
          alignment: TextAlignment.Left,
          font: font.normal!,
          fontSize: fontSize,
          bounds: {
            x: this.pageMargin,
            y: y,
            width: page.getWidth() - this.pageMargin * 2,
            height: 100
          }
        }
      )

      let currentY = y - lineHeight
      for (let i = 0; i < contentText.lines.length; i++) {
        page.drawText(`${contentText.lines[i].text}`, {
          x: this.pageMargin,
          y: currentY,
          size: fontSize,
          font: font.normal
        })
        currentY = currentY - lineHeight
      }
      y = y - contentText.lines.length * lineHeight
    }

    return { x: this.pageMargin, y: y }
  }

  async signature(doc: PDFDocument, page: PDFPage, font: TFont, position: TPosition) {
    const currentY = position.y - 50
    page.drawText('Signed by customer:', {
      x: this.pageMargin,
      y: currentY,
      font: font.bold,
      size: 12
    })

    page.drawText(`Date: ${this.agreement.createdAt?.split(' ')[0]}`, {
      x: this.pageMargin + 400,
      y: currentY,
      font: font.bold,
      size: 12
    })

    const sign = await fetch(this.agreement.signature!).then(res => res.arrayBuffer())
    const signPng = await doc.embedPng(sign)
    const imageOption = {
      x: this.pageMargin,
      y: currentY - 16 - 100,
      width: 200,
      height: 100
    }

    page.drawImage(signPng, imageOption)
  }

  async generate() {
    const pdfDoc = await PDFDocument.create()
    // resolve fonts
    const Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

    const page = pdfDoc.addPage()

    const headingPostion = this.drawHeading(page, { bold: HelveticaBold })
    const tablePosition = this.agreementTable(page, { normal: Helvetica }, headingPostion)
    const termsPosition = this.termsAndCondition(page, { normal: Helvetica }, tablePosition)
    await this.signature(pdfDoc, page, { bold: HelveticaBold }, termsPosition)

    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([pdfBytes.buffer], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    window.open(url)
  }
}

export default AgreementPDF
