import { TPassword, TRegister } from 'types/auth.types'
import { USER, AUTH } from 'app/constants'
import { TCredentials, TUserData } from 'types/auth.types'

export const userLogin = (credentials: TCredentials) => ({
  type: USER.LOGIN,
  credentials
})

export const userLoginSuccess = () => ({
  type: USER.LOGIN_SUCCESS
})

export const userLogout = () => ({
  type: USER.LOGOUT
})

export const registerUser = (user: TRegister) => ({
  type: USER.REGISTER,
  user
})

export const verifyToken = () => ({
  type: AUTH.VERIFY
})

export const verifyTokenSuccess = () => ({
  type: AUTH.VERIFY_SUCCESS
})

export const setCurrentUser = (user: TUserData) => ({
  type: USER.SET_CURRENT_USER,
  user
})

export const updateCurrentUser = (userData: TUserData) => ({
  type: USER.UPDATE,
  userData
})

export const updateCurrentUserSuccess = (userData: TUserData) => ({
  type: USER.UPDATE_SUCCESS,
  userData
})

export const resetCurrentUserPassword = (username: string, password: TPassword) => ({
  type: AUTH.RESET,
  username,
  password
})
