import find from 'lodash/find'

import Importer from './Importer'
import { importAgreement } from 'api/agreement.api'

import { EDateFormat, IImporter, TImportMode } from 'types/importExport.types'
import { TAgreementData } from 'types/agreement.types'
import { TLoanerData } from 'types/loaner.types'
import { excelDateToJSDate, isNull } from 'app/utils/importerUtils'

class AgreementImporter extends Importer implements IImporter<TAgreementData> {
  private agreements: TAgreementData[] = []
  private loaners: TLoanerData[] = []

  constructor(importMode: TImportMode, loaners: TLoanerData[]) {
    super(importMode)
    this.loaners = loaners
  }

  mapAgreement(data: any): TAgreementData {
    const {
      name,
      firstname,
      lastname,
      dob,
      licence,
      phone,
      email,
      address,
      gender,
      make,
      model,
      year,
      rego,
      colour,
      transmission,
      returned,
      mileage,
      fuel,
      signature,
      createdAt
    } = data
    const loaner = find(this.loaners, { rego })
    return {
      loanerId: loaner ? loaner.id : null,
      firstname: firstname ? firstname : name,
      lastname: lastname ? lastname : '',
      dob: excelDateToJSDate(dob, EDateFormat.DATE),
      licence,
      phone,
      email,
      address,
      gender,
      make,
      model,
      year,
      rego,
      colour,
      transmission,
      returned: !isNull(returned) ? excelDateToJSDate(returned, EDateFormat.DATETIME) : null,
      mileage: !isNull(mileage) ? mileage : null,
      fuel: !isNull(fuel) ? fuel : null,
      signature,
      createdAt: excelDateToJSDate(createdAt, EDateFormat.DATETIME)
    }
  }

  import(data: TAgreementData[]): void {
    data.forEach(async (agreement: TAgreementData) => {
      // checking if agreement exists
      if (find(this.agreements, { id: agreement.id }) === undefined) {
        await importAgreement(this.mapAgreement(agreement))
      }
      setTimeout(() => {}, 5000)
    })
  }
}

export default AgreementImporter
