import styled from 'styled-components'
import { colour } from './globalStyles'

const MAX_WIDTH = '800px'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: ${MAX_WIDTH};
  label {
    font-size: 16px;
    color: ${colour.light};
    padding-bottom: 5px;
  }
  .error {
    color: ${colour.alertHover};
    font-size: 13px;
    padding-top: 10px;
  }
  label {
    margin-bottom: 5px;
  }
  & > input[type='text'],
  & > input[type='number'],
  & > input[type='email'],
  & > input[type='password'],
  & > input[type='date'],
  & > input[type='tel'],
  & > select,
  & > textarea {
    border: 1px solid ${colour.shadow};
    padding: 10px;
    border-radius: 5px;
    &:focus {
      outline-color: ${colour.blueDisabled};
    }
    &.field-error {
      border-color: ${colour.alert} !important;
      outline-width: 0;
    }
  }
  .react-datepicker__input-container {
    display: flex;
    & > input {
      width: 100%;
      border: 1px solid ${colour.shadow};
      padding: 10px;
      border-radius: 5px;
    }
  }
`

export const FormButtonWrapper = styled.div<{ fullWidth?: boolean; align?: string }>`
  align-items: center;
  display: flex;
  justify-content: ${({ align }) => {
    if (align === 'right') return 'flex-end'
    if (align === 'center') return 'center'
    return 'space-between'
  }};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : MAX_WIDTH)};
  position: relative;
`

export const StickyBottomForm = styled.form`
  background: white;
  bottom: -20px;
  padding-bottom: 20px;
  padding-top: 15px;
  position: sticky;
`

export const AutoComplete = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: ${MAX_WIDTH};
  position: relative;
  label {
    font-size: 16px;
    color: ${colour.light};
    padding-bottom: 5px;
  }
  .error {
    color: ${colour.alertHover};
    font-size: 13px;
    padding-top: 10px;
  }
  label {
    margin-bottom: 5px;
  }
  .autocomplete_field {
    position: relative;
    display: flex;
    align-items: center;

    & > input {
      border: 1px solid ${colour.shadow};
      padding: 10px 30px 10px 10px;
      border-radius: 5px;
      box-sizing: border-box;
      width: 100%;
      cursor: pointer;
      &:focus {
        outline-color: ${colour.blueDisabled};
      }
      &.field-error {
        border-color: ${colour.alert} !important;
        outline-width: 0;
      }
    }

    & > svg {
      position: absolute;
      right: 5px;
      cursor: pointer;
    }
  }
  .autocomplete_option {
    border: 1px solid ${colour.shadow};
    border-radius: 5px;
    margin-top: 5px;
    position: absolute;
    width: 100%;
    top: 73px;
    background: white;
    max-height: 250px;
    overflow-y: auto;
    z-index: 999;

    ul {
      margin: 0;
      list-style: none;
      padding: 10px;
      li {
        padding: 3px 10px;
        cursor: pointer;
        &:first-child {
          display: flex;
          position: relative;
          align-items: center;
          input {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid ${colour.shadow};
            border-radius: 5px;
            padding: 5px;
            outline: ${colour.shadow};
          }
          svg {
            position: absolute;
            right: 15px;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
`
export const SearchWrap = styled.form`
  display: flex;
  padding: 20px 55px;

  & > * {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding-right: 20px;
    & > label {
      margin: 0 10px;
    }
  }
`

export const FieldWrap = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  width: 35%;
  label {
    padding-right: 15px;
  }
  input {
    border: 1px solid ${colour.shadow};
    padding: 10px;
    border-radius: 5px;
    flex: 1;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: flex;
    flex: 1;
  }
`
