import React, { useCallback } from 'react'
import moment from 'moment'

import { ValidationRules, UseFormMethods, FieldValues } from 'react-hook-form'
import { FormWrapper } from 'app/styles/form.styles'

const DATE_FORMAT = ['DD/MM/YYYY', 'DD-MM-YYYY']
const datePattern = /^(?:(?:31(\/|-)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/

declare type TFieldPorps = {
  label: string
  value: any
  name: string
  maxDate?: Date
  validationRules?: ValidationRules
  formHook: UseFormMethods<FieldValues>
  hideError?: boolean
}

const DateField: React.FC<TFieldPorps> = ({
  label,
  value,
  name,
  maxDate,
  validationRules,
  hideError,
  formHook
}) => {
  const { register, errors, setValue } = formHook
  const validateDate = useCallback(
    (date: string): boolean => {
      if (!maxDate) return true
      return moment(date, DATE_FORMAT).toDate() <= maxDate
    },
    [value]
  )
  return (
    <FormWrapper>
      <label htmlFor={name}>
        {label} <small>(DD/MM/YYYY)</small>
      </label>
      <input
        className={errors?.[name] ? 'field-error' : ''}
        data-test-id={`${name}_input`}
        name={name}
        id={name}
        type="text"
        defaultValue={value}
        onChange={e => {
          setValue(name, e.target.value.substring(0, 10))
        }}
        ref={register({ ...validationRules, validate: validateDate, pattern: datePattern })}
      />
      {!hideError && errors?.[name]?.type === 'pattern' && (
        <span data-test-id="error-message" className="error">
          {`${label} is invalid date.`}
        </span>
      )}
      {!hideError && errors?.[name]?.type === 'validate' && (
        <span data-test-id="error-message" className="error">
          {`${label} must ealier than ${moment(maxDate).format(DATE_FORMAT[0])}.`}
        </span>
      )}
      {!hideError && errors?.[name]?.type === 'required' && (
        <span data-test-id="error-message" className="error">
          {`${label} is required.`}
        </span>
      )}
    </FormWrapper>
  )
}

export default DateField
