import React from 'react'

import spinner from 'app/image/spinner.svg'

import {SpinnerWrap, SpinnerIcon} from './spinner.style'

const Spinner: React.FC = () => {
  return <SpinnerWrap><SpinnerIcon src={spinner} alt='Loading...' /></SpinnerWrap>

}

export default Spinner
