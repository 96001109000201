import { takeEvery, call, put } from '@redux-saga/core/effects'
import toastr from 'Toastr.config'

import { getSettingsData, updateCompanyProfile } from 'api/settings.api'
import { enableLoading, setSettings, updateCompanySuccess, disableLoading } from '../actions'
import { SETTINGS, SETTINGS_MESSAGE } from 'app/constants'

import { TSettingsData } from 'types/settings.types'
import { TCompanyParam } from 'types/settings.types'

export default function* watchSettingsSaga() {
  yield takeEvery(SETTINGS.GET, handleGetSettings)
  yield takeEvery(SETTINGS.UPDATE_COMPANY, handleUpdateCompany)
}

function* handleGetSettings() {
  try {
    yield put(enableLoading())
    const settings: TSettingsData = yield call(getSettingsData)
    yield put(setSettings(settings))
    yield put(disableLoading())
  } catch (error) {
    yield put(disableLoading())
    yield toastr.error(SETTINGS_MESSAGE.LOAD_FAILED)
    //set error
  }
}

function* handleUpdateCompany(param: TCompanyParam) {
  try {
    yield put(enableLoading())
    yield call(updateCompanyProfile, param.company)
    yield put(updateCompanySuccess(param.company))
    yield put(disableLoading())
    yield toastr.success(SETTINGS_MESSAGE.UPDATE_COMPANY_SUCCESS)
  } catch (error) {
    yield put(disableLoading())
    yield toastr.error(SETTINGS_MESSAGE.UPDATE_COMPANY_FAILED)
    // set error
  }
}
