import { IMPORT_EXPORT } from 'app/constants'
import { TImportDataAction, TImportFileInfo } from 'types/importExport.types'

export const importFileReducer = (
  state = { headers: [], data: [] } as TImportFileInfo,
  action: TImportDataAction
) => {
  if (action.type === IMPORT_EXPORT.PROCESS_FILE_SUCCESS) return action.fileContent
  return state
}
