import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import Button from '@material-ui/core/Button'

import { Row, Col } from 'app/components/common/grid'
import Field from 'app/components/common/formElement/Field'
import Spinner from 'app/components/common/spinner'
import toastr from 'app/components/common/tips'
import ImageUpload from 'app/components/common/imageUpload'
import useImage from 'app/components/hooks/isDefaultImage'

import { getLoadingState, getCompanyProfileSelector } from 'app/store/selector'
import { updateCompany } from 'app/store/actions'
import defaultLogo from 'app/image/logo.png'

import { TCompanyData } from 'types/settings.types'

import { FormButtonWrapper } from 'app/styles/form.styles'
import { buttonStyle } from 'app/styles/button.styles'

const CompanyProfile = () => {
  const dispatch = useDispatch()
  const classes = buttonStyle()
  const formHook = useForm()
  const [image, setImage] = useState<string>(defaultLogo)
  const { isDefaultImage } = useImage(image, '', defaultLogo)
  const [company, setCompany] = useState<TCompanyData>({
    companyName: '',
    abnAcn: '',
    phone: '',
    address: ''
  })

  const loading = useSelector(getLoadingState)
  const companyProfile = useSelector(getCompanyProfileSelector)

  useEffect(() => {
    if (!isEmpty(companyProfile)) setCompany(companyProfile)
  }, [companyProfile])

  const onSubmit = (data: TCompanyData) => {
    dispatch(updateCompany(data))
  }

  if (loading) return <Spinner />

  return (
    <>
      <Row data-test-id="company-profile-panel">
        <Col xs={8}>
          <h3>Company profile</h3>
          <form onSubmit={formHook.handleSubmit(onSubmit)} data-test-id="company-profile-form">
            <Field
              label="Company name"
              name="companyName"
              value={company.companyName}
              validationRules={{ required: true }}
              formHook={formHook}
            />
            <Field
              label="ABN or ACN"
              name="abnAcn"
              value={company.abnAcn}
              validationRules={{ required: true }}
              formHook={formHook}
            />
            <Field
              label="Company phone"
              name="phone"
              value={company.phone}
              validationRules={{ required: true }}
              formHook={formHook}
            />
            <Field
              label="Company address"
              name="address"
              value={company.address}
              validationRules={{ required: true }}
              formHook={formHook}
            />
            <FormButtonWrapper>
              <Button
                type="submit"
                className={classes.saveButton}
                data-test-id="company-profile-submit-button"
              >
                Save
              </Button>
            </FormButtonWrapper>
          </form>
        </Col>
        {/* <Col xs={4}>
          <h3>Company logo</h3>
          <div>Click current logo or drag n drop an image on current logo to upload a new logo</div>
          <ImageUpload image={image} uploadImage={setImage} />
          <br />
          <FormButtonWrapper>
            <Button
              className={classes.saveButton}
              onClick={() => {
                //TODO: uploadImage(image)
              }}
              disabled={isDefaultImage()}
            >
              Upload logo
            </Button>
            <Button
              className={classes.cancelButton}
              onClick={() => setImage(defaultLogo)}
              disabled={isDefaultImage()}
            >
              Reset logo
            </Button>
          </FormButtonWrapper>
        </Col> */}
      </Row>
    </>
  )
}

export default CompanyProfile
