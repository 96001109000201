import moment from 'moment'

import { DATE_TO_DB } from 'app/constants'
import { AGREEMENT } from 'app/constants'

import { TAgreementData, TGetAllAgreementAction } from 'types/agreement.types'

export const agreementReducer = (state: TAgreementData[] = [], action: TGetAllAgreementAction) => {
  if (action.type === AGREEMENT.GET_ALL_SUCCESS) return [...state, ...action.agreements!]

  if (action.type === AGREEMENT.CREATE_SUCCESS) return [...state, action.agreement!]

  if (action.type === AGREEMENT.RETURN_SUCCESS)
    return state.map((agreement: TAgreementData) => {
      if (agreement.id === action.agreementId!)
        return { ...agreement, returned: moment().format(DATE_TO_DB) }
      return agreement
    })

  return state
}
