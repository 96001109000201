import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import orderBy from 'lodash/orderBy'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import Divider from '@material-ui/core/Divider'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import PopoverOptions from 'app/components/common/popoverOption'
import FlyinWindow from 'app/components/common/flyinWindow'
import Spinner from 'app/components/common/spinner'
import CustomerForm from 'app/components/forms/customer'
import CommentForm from 'app/components/forms/comment'

import { ADD_COMMENTS, CREATE_CUSTOMER, EDIT_CUSTOMER } from './customer.constants'
import { getAllCustomers, getLoadingState } from 'app/store/selector'
import { getCustomers } from 'app/store/actions'

import { TCustomerProps, TCustomerData, TCommentData } from 'types/customer.types'

import { PageHeader, pageStyles } from 'app/styles/page.styles'
import { buttonStyle } from 'app/styles/button.styles'
import { CommentHistoryHeading, CommentRow } from './customer.styles'

const Customer: React.FC<TCustomerProps> = () => {
  const dispatch = useDispatch()
  const pageClasses = pageStyles()
  const buttonClasses = buttonStyle()
  const [formType, setFormType] = useState<string | null>(null)
  const [customer, setCustomer] = useState<TCustomerData>({} as TCustomerData)
  const [page, setPage] = useState(0)
  const rowPerPage = 10

  const customers = useSelector(getAllCustomers)
  const loading = useSelector(getLoadingState)

  useEffect(() => {
    if (customers.length === 0) dispatch(getCustomers())
  }, [])

  if (loading) {
    return <Spinner />
  }

  const editCustomerPanel = (c: TCustomerData) => {
    setCustomer(c)
    setFormType(EDIT_CUSTOMER)
  }

  const commentCustomerPanel = (c: TCustomerData) => {
    setCustomer(c)
    setFormType(ADD_COMMENTS)
  }

  return (
    <>
      <PageHeader>
        <h1>Customer</h1>
        <Button
          data-test-id="create-button"
          onClick={() => setFormType(CREATE_CUSTOMER)}
          className={buttonClasses.createButton}
          startIcon={<AddOutlinedIcon />}
        >
          Create
        </Button>
      </PageHeader>
      <Paper className={pageClasses.paper}>
        <Table aria-label="Loaner list">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Driver Licence</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Address</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers
              .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
              .map((customer: TCustomerData) => (
                <TableRow key={customer.id}>
                  <TableCell></TableCell>
                  <TableCell>
                    {customer.lastname
                      ? `${customer.lastname}, ${customer.firstname}`
                      : `${customer.firstname}`}
                  </TableCell>
                  <TableCell>{customer.licence}</TableCell>
                  <TableCell>{customer.phone}</TableCell>
                  <TableCell>{customer.address}</TableCell>
                  <TableCell align="right" className={pageClasses.options}>
                    <PopoverOptions id={customer.id!} Trigger={MoreVertIcon}>
                      <Button
                        onClick={() => editCustomerPanel(customer)}
                        data-test-id="edit-customer"
                        className={buttonClasses.optionsButton}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => commentCustomerPanel(customer)}
                        data-test-id="comments-customer"
                        className={buttonClasses.optionsButton}
                      >
                        Comments
                      </Button>
                    </PopoverOptions>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={customers.length}
          rowsPerPage={10}
          page={page}
          onPageChange={(e, num) => setPage(num)}
        />
      </Paper>
      <FlyinWindow
        isOpen={!!formType}
        onClose={() => setFormType(null)}
        heading={formType!}
        maxWidth={600}
      >
        {formType === ADD_COMMENTS && (
          <>
            <CommentHistoryHeading>Comments History</CommentHistoryHeading>
            {orderBy(customer?.comments || [], ['date'], ['desc']).map(
              (commentData: TCommentData, index: number) => {
                return (
                  <CommentRow key={index} data-test-id="customer-comment-item">
                    <span>{commentData.date!}</span>
                    <span>{commentData.message}</span>
                  </CommentRow>
                )
              }
            )}
            <Divider style={{ margin: '10px 0' }} />
            <CommentForm customerId={customer?.id!} closeFlyinWindow={setFormType} />
          </>
        )}
        {formType === CREATE_CUSTOMER && <CustomerForm closeFlyinWindow={setFormType} />}
        {formType === EDIT_CUSTOMER && (
          <CustomerForm customer={customer} closeFlyinWindow={setFormType} />
        )}
      </FlyinWindow>
    </>
  )
}

export default Customer
