import { LOANER } from 'app/constants'
import { TLoanerData } from 'types/loaner.types'

export const getLoaners = () => ({
  type: LOANER.GET_ALL
})

export const setLoaners = (loaners: TLoanerData[]) => ({
  type: LOANER.GET_ALL_SUCCESS,
  loaners
})

export const createLoaner = (loaner: TLoanerData) => ({
  type: LOANER.CREATE,
  loaner
})

export const createLoanerSuccess = (loaner: TLoanerData) => ({
  type: LOANER.CREATE_SUCCESS,
  loaner
})

export const updateLoaner = (loaner: TLoanerData) => ({
  type: LOANER.UPDATE,
  loaner
})

export const updateLoanerSuccess = (loaner: TLoanerData) => ({
  type: LOANER.UPDATE_SUCCESS,
  loaner
})

export const updateLoanerAvailability = (loanerId: string, availability: boolean) => ({
  type: LOANER.UPDATE_AVAILABILITY,
  loanerId,
  availability
})

export const updateLoanerAvailabilitySuccess = (loanerId: string, availability: boolean) => ({
  type: LOANER.UPDATE_AVAILABILITY_SUCCESS,
  loanerId,
  availability
})

export const deleteLoanerAction = (loanerId: string) => ({
  type: LOANER.DELETE,
  loanerId
})

export const deleteLoanerSuccess = (loanerId: string) => ({
  type: LOANER.DELETE_SUCCESS,
  loanerId
})
