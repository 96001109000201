import React from 'react'
import { useForm } from 'react-hook-form'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'

import Field from 'app/components/common/formElement/Field'
import Select from 'app/components/common/formElement/Select'

import { buttonStyle } from 'app/styles/button.styles'
import { FormButtonWrapper } from 'app/styles/form.styles'

import { TAgreementDetails, TAgreement } from './agreementDetails.types'

const fuelOptions = [
  { label: 'Please select ...', value: '' },
  { label: '1/4', value: '1' },
  { label: '1/2', value: '2' },
  { label: '3/4', value: '3' },
  { label: 'full', value: '4' }
]

const AgreementDetails: React.FC<TAgreementDetails> = ({ handleBack, handleNext }) => {
  const formHook = useForm()
  const classes = buttonStyle()

  const onSubmit = (data: TAgreement) =>
    handleNext({
      ...data,
      fuel: data.fuel ? data.fuel : 0,
      mileage: data.mileage ? data.mileage : ''
    })

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <h3>Agreement details</h3>
            <form data-test-id="agreement-form">
              <Field label="Mileage" name="mileage" value="" formHook={formHook} />
              <Select label="Fuel" name="fuel" value="" options={fuelOptions} formHook={formHook} />
            </form>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}>
            <FormButtonWrapper fullWidth>
              <Button className={classes.previousButton} onClick={handleBack}>
                Back
              </Button>
              <Button
                data-test-id="next-button"
                className={classes.saveButton}
                onClick={formHook.handleSubmit(onSubmit)}
              >
                Next
              </Button>
            </FormButtonWrapper>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default AgreementDetails
