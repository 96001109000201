import mapAccounts from 'db/accounts'

class FirestoreAPI {
  static prefixedTable(table: string): string {
    const prefix = mapAccounts(window.location.hostname)
    return prefix + table
  }
}

export default FirestoreAPI
