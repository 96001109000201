import styled from 'styled-components'

export const SpinnerWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  background: rgba(255,255,255,0.6);
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
`

export const SpinnerIcon = styled.img`
  width: 80px;
  height: 80px;
`