export const CUSTOMER = {
  GET_ALL: 'GET_ALL_CUSTOMERS',
  GET_ALL_SUCCESS: 'GET_ALL_CUSTOMER_SUCCESS',
  CREATE: 'CREATE_CUSTOMER',
  CREATE_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  UPDATE: 'UPDATE_CUSTOMER',
  UPDATE_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  COMMENT: 'COMMENT_CUSTOMER',
  COMMENT_SUCCESS: 'COMMENT_CUSTOMER_SUCCESS'
}
