import styled from 'styled-components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { colour } from './globalStyles'
import { TPageElementStyle } from './style.types'

export const pageStyles = makeStyles(
  (): TPageElementStyle => ({
    paper: {
      margin: '0 40px'
    },
    card: {
      margin: '0'
    },
    loginWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '500px',
      padding: '20px 40px 40px',
      flexDirection: 'column',
      '& form': {
        width: '100%'
      }
    },
    unavailable: {
      '& > td': {
        color: 'rgba(0, 0, 0, 0.4) !important'
      }
    },
    leasing: {
      borderColor: `${colour.fail} !important`,
      color: `${colour.fail} !important`
    },
    returned: {
      borderColor: `${colour.success} !important`,
      color: `${colour.success} !important`
    },
    options: {
      color: `rgba(0, 0, 0, 0.5) !important`
    },
    suspended: {
      '& > td': {
        color: 'rgba(0, 0, 0, 0.4) !important'
      }
    }
  })
)

export const PageHeader = styled.div`
  align-items: center;
  background-color: ${colour.lightGrey};
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 40px;
  position: sticky;
  top: 0;
  z-index: 99;
`

export const TabPanel = styled.div`
  padding: 40px;
  min-height: 300px;
  position: relative;
`

export const Section = styled.div`
  padding: 40px;
`
