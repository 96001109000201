import { TCustomerData, TCommentData } from 'types/customer.types'
import CustomerFirestoreAPI from './firestore/customerFirestoreAPI'

/**
 * Get all customers list
 * @returns {Object[]}
 */
export const getAllCustomers = async (withTimestamp: boolean = false): Promise<TCustomerData[]> => {
  return await CustomerFirestoreAPI.getAll(withTimestamp)
}

/**
 *  Save a new customer into database
 * @param {Object} customer
 * @returns {Object} customer
 */
export const createCustomer = async (customer: TCustomerData): Promise<TCustomerData> => {
  return await CustomerFirestoreAPI.createCustomer(customer)
}

/**
 * Update exists customer with new data
 * @param {Object} customer
 */
export const updateCustomer = async (customer: TCustomerData): Promise<void> => {
  await CustomerFirestoreAPI.updateCustomer(customer)
}

/**
 * Comment customer by customer Id
 * @param {string} customerId
 * @param {Object} comment
 */
export const commentCustomer = async (customerId: string, comment: TCommentData): Promise<void> => {
  await CustomerFirestoreAPI.commentCustomer(customerId, comment)
}
