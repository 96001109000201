import { FileUpload } from 'use-file-upload'

export type TImportOpt = {
  entity: string
  mode: 'override' | 'duplicate'
}

export type TExportData = {
  entity: string
}

export type TImportDataAction = {
  type: string
  fileContent: TImportFileInfo
}

export type TImportMode = 'override' | 'duplicate'

export interface IImporter<T> {
  import(data: T[]): void
}

export type TImportFileInfo = {
  headers: string[]
  data: any[]
}

export type TFileProccessParam = {
  type: string
  file: FileUpload
}

export type TImportDataParam = {
  type: string
  opt: TImportOpt
  data: any[]
}

export type TExportDataParam = {
  type: string
  entity: string
}

export enum EDateFormat {
  DATE = 'DD/MM/YYYY',
  DATETIME = 'YYYY-MM-DD HH:mm:ss'
}
