import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'

import { Row, Col } from 'app/components/common/grid'
import Field from 'app/components/common/formElement/Field'
import Button from '@material-ui/core/Button'
import Password from 'app/components/common/formElement/Password'

import { FormButtonWrapper } from 'app/styles/form.styles'
import { buttonStyle } from 'app/styles/button.styles'

import { IUser } from './settings.type'
import Select from 'app/components/common/formElement/Select'

const CreateUser = () => {
  const classes = buttonStyle()
  const formHook = useForm()
  const password = useRef()
  password.current = formHook.watch('password', '')

  const onSubmit = (data: IUser) => {
    if (!data.avatar) data.avatar = ''
  }

  return (
    <Row data-test-id="company-profile-panel">
      <Col xs={8}>
        <h3>Creat User</h3>
        <form onSubmit={formHook.handleSubmit(onSubmit)} data-test-id="company-profile-form">
          <Field
            label="Firstname"
            name="firstname"
            value={''}
            validationRules={{ required: true }}
            formHook={formHook}
          />
          <Field
            label="Lastname"
            name="lastname"
            value={''}
            validationRules={{ required: true }}
            formHook={formHook}
          />
          <Field
            label="Email"
            name="email"
            value={''}
            validationRules={{ required: true }}
            formHook={formHook}
          />
          <Field
            label="Phone"
            name="phone"
            value={''}
            validationRules={{ required: true }}
            formHook={formHook}
          />
          <Select
            label="Role"
            formHook={formHook}
            name="role"
            options={[
              { label: 'Please select...', value: '' },
              { label: 'Admin', value: 'admin' }
            ]}
            value={''}
            validationRules={{ required: true }}
          />
          <Password
            label="Password"
            name="password"
            value=""
            formHook={formHook}
            validationRules={{ required: true }}
          />
          <Password
            label="Confirm password"
            name="confirmPassword"
            value=""
            formHook={formHook}
            validationRules={{ validate: value => value === password.current, required: true }}
          />
          <FormButtonWrapper>
            <Button
              type="submit"
              className={classes.saveButton}
              data-test-id="create-user-submit-button"
            >
              Save
            </Button>
          </FormButtonWrapper>
        </form>
      </Col>
    </Row>
  )
}

export default CreateUser
