import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col } from 'app/components/common/grid'
import Field from 'app/components/common/formElement/Field'
import Button from '@material-ui/core/Button'
import Spinner from 'app/components/common/spinner'
import ImageUpload from 'app/components/common/imageUpload'
import useImage from 'app/components/hooks/isDefaultImage'

import defaultLogo from 'app/image/logo.png'
import { getCurrentUserSelector, getLoadingState } from 'app/store/selector'
import { updateCurrentUser } from 'app/store/actions'

import { FormButtonWrapper } from 'app/styles/form.styles'
import { buttonStyle } from 'app/styles/button.styles'

import { TUserData } from 'types/auth.types'

const UserProfile = () => {
  const dispatch = useDispatch()
  const classes = buttonStyle()
  const formHook = useForm()
  const [image, setImage] = useState<string>(defaultLogo)
  const { isDefaultImage } = useImage(image, '', defaultLogo)

  const loading = useSelector(getLoadingState)
  const currentUser = useSelector(getCurrentUserSelector)

  const onSubmit = (user: TUserData) => {
    dispatch(updateCurrentUser({ ...currentUser, ...user }))
  }

  if (loading) return <Spinner />

  return (
    <>
      <Row data-test-id="user-profile-panel">
        <Col xs={8}>
          <h3>User Profile</h3>
          <form onSubmit={formHook.handleSubmit(onSubmit)} data-test-id="user-profile-form">
            <Field
              label="First name"
              name="firstname"
              value={currentUser.firstname}
              validationRules={{ required: true }}
              formHook={formHook}
            />
            <Field
              label="Last name"
              name="lastname"
              value={currentUser.lastname}
              validationRules={{ required: true }}
              formHook={formHook}
            />
            <Field
              label="Mobile"
              name="phone"
              value={currentUser.phone}
              validationRules={{ required: true }}
              formHook={formHook}
            />
            <Field
              label="Email"
              name="email"
              value={currentUser.email}
              validationRules={{ required: true }}
              formHook={formHook}
            />
            <FormButtonWrapper>
              <Button
                type="submit"
                className={classes.saveButton}
                data-test-id="user-profile-submit-button"
              >
                Save
              </Button>
            </FormButtonWrapper>
          </form>
        </Col>
        {/* <Col xs={4}>
          <h3>User avatar</h3>
          <div>
            Click current avatar or drag n drop an image on current avatar to upload a new avatar
          </div>
          <ImageUpload image={image} uploadImage={setImage} />
          <br />
          <FormButtonWrapper>
            <Button
              className={classes.saveButton}
              onClick={() => {
                //TODO: uploadImage(image)
              }}
              disabled={isDefaultImage()}
            >
              Upload avatar
            </Button>
            <Button
              className={classes.cancelButton}
              onClick={() => setImage(defaultLogo)}
              disabled={isDefaultImage()}
            >
              Reset avatar
            </Button>
          </FormButtonWrapper>
        </Col> */}
      </Row>
    </>
  )
}

export default UserProfile
