import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Paper from '@material-ui/core/Paper'

import Spacer from 'app/components/common/spacer'
import CustomerDetails from './steps/customerDetails'
import LoanerDetails from './steps/loanerDetails'
import AgreementDetails from './steps/agreementDetails'
import CustomerSignature from './steps/signature'

import { createAgreement } from 'app/store/actions'
import { DATE_TO_DB } from 'app/constants'

import { TAgreementProps } from 'types/agreement.types'

import { pageStyles } from 'app/styles/page.styles'
import { buttonStyle } from 'app/styles/button.styles'
import { stepStyles } from 'app/styles/step.styles'

const getSteps = () => [
  'Customer details',
  'Loaner details',
  'Agreement details',
  'Customer signature'
]

const getStepContent = (
  stepIndex: number,
  handleBack: () => void,
  handleNext: (entity: any) => void
) => {
  switch (stepIndex) {
    case 0:
      return <CustomerDetails handleNext={handleNext!} />
    case 1:
      return <LoanerDetails handleBack={handleBack} handleNext={handleNext} />
    case 2:
      return <AgreementDetails handleBack={handleBack} handleNext={handleNext} />
    case 3:
      return <CustomerSignature handleBack={handleBack} handleNext={handleNext} />
    default:
      return null
  }
}

const AgreementCreate: React.FC<TAgreementProps> = ({ postCreate }) => {
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState<number>(0)
  const classes = { ...pageStyles(), ...buttonStyle(), ...stepStyles() }
  const [agreement, setAgreement] = useState({} as any)
  const steps = getSteps()

  useEffect(() => {
    if (activeStep === steps.length - 1) {
      dispatch(createAgreement({ ...agreement, createdAt: moment().format(DATE_TO_DB) }))
      postCreate()
    }
  }, [agreement])

  const handleNext = useCallback(
    entity => {
      setAgreement({ ...agreement, ...entity })
      if (activeStep < steps.length - 1) {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    },
    [activeStep]
  )

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }, [activeStep])

  return (
    <>
      <Paper className={classes.paper}>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map(label => (
            <Step id="step" className={classes.primaryIcon} key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
      <Spacer />
      <Paper className={classes.paper}>{getStepContent(activeStep, handleBack, handleNext)}</Paper>
    </>
  )
}

export default AgreementCreate
