import { SETTINGS } from 'app/constants'

import { TGetSettingsAction, TSettingsData, TCompanyData } from 'types/settings.types'

export const settingsReducer = (
  state: TSettingsData = { companyProfile: {} as TCompanyData },
  action: TGetSettingsAction
) => {
  if (action.type === SETTINGS.GET_SUCCESS) return action.settings

  if (action.type === SETTINGS.UPDATE_COMPANY_SUCCESS)
    return { ...state, companyProfile: { ...state.companyProfile, ...action.company! } }

  return state
}
