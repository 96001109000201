import { AGREEMENT } from 'app/constants'
import { TAgreementData } from 'types/agreement.types'

export const getAgreements = () => ({
  type: AGREEMENT.GET_ALL
})

export const setAgreements = (agreements: TAgreementData[]) => ({
  type: AGREEMENT.GET_ALL_SUCCESS,
  agreements
})

export const createAgreement = (agreement: TAgreementData) => ({
  type: AGREEMENT.CREATE,
  agreement
})

export const createAgreementSuccess = (agreement: TAgreementData) => ({
  type: AGREEMENT.CREATE_SUCCESS,
  agreement
})

export const viewAgreement = (agreementId: string) => ({
  type: AGREEMENT.VIEW,
  agreementId
})

export const returnLoanerAction = (agreementId: string) => ({
  type: AGREEMENT.RETURN,
  agreementId
})

export const returnLoanerSuccess = (agreementId: string) => ({
  type: AGREEMENT.RETURN_SUCCESS,
  agreementId
})
