export const LOANER = {
  GET_ALL: 'GET_ALL_LOANERS',
  GET_ALL_SUCCESS: 'GET_ALL_LOANER_SUCCESS',
  CREATE: 'CREATE_LOANER',
  CREATE_SUCCESS: 'CREATE_LOANER_SUCCESS',
  UPDATE: 'UPDATE_LOANER',
  UPDATE_SUCCESS: 'UPDATE_LOANER_SUCCESS',
  UPDATE_AVAILABILITY: 'UPDATE_LOANER_AVAILABILITY',
  UPDATE_AVAILABILITY_SUCCESS: 'UPDATE_LOANER_AVAILABILITY_SUCCESS',
  DELETE: 'DELETE_LOANER',
  DELETE_SUCCESS: 'DELETE_LOANER_SUCCESS'
}
