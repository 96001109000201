import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import Button from '@material-ui/core/Button'

import Email from 'app/components/common/formElement/Email'
import Field from 'app/components/common/formElement/Field'
import Password from 'app/components/common/formElement/Password'

import { registerUser } from 'app/store/actions'

import { TRegister } from 'types/auth.types'

import { FormButtonWrapper } from 'app/styles/form.styles'
import { buttonStyle } from 'app/styles/button.styles'

const RegisterForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = buttonStyle()
  const formHook = useForm()
  const password = useRef()
  password.current = formHook.watch('password', '')

  const onSubmit = async (user: TRegister) => {
    dispatch(registerUser(user))
    history.push('/login')
  }

  return (
    <>
      <form onSubmit={formHook.handleSubmit(onSubmit)} data-test-id="login-form">
        <Field
          label="Firstname"
          name="firstname"
          value=""
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Lastname"
          name="lastname"
          value=""
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Field
          label="Phone"
          name="phone"
          value=""
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Email
          label="email"
          name="email"
          value=""
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Password
          label="Password"
          name="password"
          value=""
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <Password
          label="Confirm password"
          name="confirmPassword"
          value=""
          formHook={formHook}
          validationRules={{ validate: value => value === password.current, required: true }}
        />

        <FormButtonWrapper align="center">
          <Button type="submit" className={classes.saveButton} data-test-id="login-button">
            Regist
          </Button>
        </FormButtonWrapper>
      </form>
    </>
  )
}

export default RegisterForm
