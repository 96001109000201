import React from 'react'

import CloseIcon from '@material-ui/icons/Close'

import { FlyinWindowWrapper, FlyinWindowHeading, FlyinWindowContent } from './flyinWindow.style'
import {TFlyinWindowProps} from './flyinWindow.types'

const FlyinWindow:React.FC<TFlyinWindowProps> = ({ heading = '', maxWidth = 300, isOpen = false, onClose, children }) => {
  return (
    <>
      <FlyinWindowWrapper maxWidth={maxWidth} isOpen={isOpen}>
        <FlyinWindowHeading data-test-id="flyin-window-title">
          <span>{heading}</span>
          <CloseIcon onClick={() => onClose(false)} data-test-id="flyin-window-close-button" />
        </FlyinWindowHeading>
        <FlyinWindowContent>{children}</FlyinWindowContent>
      </FlyinWindowWrapper>
    </>
  )
}

export default FlyinWindow
