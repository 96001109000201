import React, { useCallback, useState, useRef, useEffect } from 'react'
import { ValidationRules, FieldValues, UseFormMethods } from 'react-hook-form'

import useClickOutside from 'app/components/hooks/clickOutside'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CloseIcon from '@material-ui/icons/Close'

import { AutoComplete } from 'app/styles/form.styles'

export type TSelectWithSearchOption = {
  label: string
  value: any
}

declare type TFieldPorps = {
  label: string
  value: any
  name: string
  options: TSelectWithSearchOption[]
  validationRules?: ValidationRules
  formHook: UseFormMethods<FieldValues>
  hideError?: boolean
  onSelect: (option: TSelectWithSearchOption) => void
}

const SelectWithSearch: React.FC<TFieldPorps> = ({
  label,
  value,
  name,
  options,
  validationRules,
  formHook,
  hideError,
  onSelect
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { isClickOutside } = useClickOutside(ref)
  const { register, errors, setValue } = formHook
  const [searchValue, setSearchValue] = useState<string>('')
  const [isOptionVisible, setIsOptionVisible] = useState<boolean>(false)

  const onItemSelected = useCallback(
    (option: TSelectWithSearchOption) => {
      setValue(name, option.label)
      onSelect(option)
    },
    [options]
  )

  const filterOptionList = useCallback(() => {
    return options.filter((option: TSelectWithSearchOption) => {
      return Object.values(option.value).join(',').toLowerCase().includes(searchValue.toLowerCase())
    })
  }, [searchValue, options])

  useEffect(() => {
    setIsOptionVisible(!!isClickOutside)
  }, [isClickOutside])

  return (
    <AutoComplete ref={ref}>
      <label htmlFor={name}>{label}</label>
      <div className="autocomplete_field">
        <input
          className={errors?.[name] ? 'field-error' : ''}
          data-test-id={`${name}_input`}
          name={name}
          id={name}
          type="text"
          defaultValue={value}
          readOnly
          onClick={() => setIsOptionVisible(!isOptionVisible)}
          ref={register({ ...validationRules })}
        />
        <ArrowDropDownIcon onClick={() => setIsOptionVisible(!isOptionVisible)} />
      </div>

      {!isClickOutside && isOptionVisible && (
        <div className="autocomplete_option">
          <ul>
            <li>
              <input
                type="text"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
              {searchValue && (
                <CloseIcon
                  onClick={() => {
                    setSearchValue('')
                  }}
                />
              )}
            </li>
            {filterOptionList().map((option, index) => {
              return (
                <li
                  data-test-id={`${name}_option_${index}`}
                  key={index}
                  onClick={() => {
                    onItemSelected(option)
                    setIsOptionVisible(false)
                  }}
                >
                  {option.label}
                </li>
              )
            })}
          </ul>
        </div>
      )}

      {!hideError && errors?.[name]?.type === 'required' && (
        <span data-test-id="error-message" className="error">
          {`${label} is required.`}
        </span>
      )}
    </AutoComplete>
  )
}

export default SelectWithSearch
