import { takeEvery, call, put } from '@redux-saga/core/effects'
import { getCurrentUser, login, logout, register, updateCurrentUser } from 'api/auth.api'
import toastr from 'Toastr.config'

import {
  setCurrentUser,
  userLoginSuccess,
  disableLoading,
  enableLoading,
  updateCurrentUserSuccess
} from '../actions'
import { SETTINGS_MESSAGE, USER } from 'app/constants'

import { TAuthAction, TUserData, TUserParam } from 'types/auth.types'

export default function* watchUserSaga() {
  yield takeEvery(USER.REGISTER, handleUserRegister)
  yield takeEvery(USER.LOGIN, handleUserLogin)
  yield takeEvery(USER.LOGOUT, handleUserLogout)
  yield takeEvery(USER.UPDATE, handleUpdateCurrentUser)
}

function* handleUserLogin(param: TAuthAction) {
  try {
    yield put(enableLoading())

    const auth: boolean = yield call(login, param.credentials!)
    if (auth) {
      const user: TUserData = yield call(getCurrentUser)
      yield put(setCurrentUser(user))
      yield put(userLoginSuccess())
      yield toastr.success(SETTINGS_MESSAGE.LOGIN_SUCCESS)
    } else {
      yield toastr.error(SETTINGS_MESSAGE.LOGIN_FAILED)
    }
    yield put(disableLoading())
  } catch (error) {
    // set error
    yield put(disableLoading())
    yield toastr.error(SETTINGS_MESSAGE.LOGIN_FAILED)
  }
}

function* handleUserLogout() {
  try {
    yield call(logout)
  } catch (error) {
    //set error
  }
}

function* handleUserRegister(param: TUserParam) {
  yield call(register, param.user!)
}

function* handleUpdateCurrentUser(param: TUserParam) {
  try {
    yield put(enableLoading())
    yield call(updateCurrentUser, param.userData!)
    yield put(updateCurrentUserSuccess(param.userData!))
    yield put(disableLoading())
    yield toastr.success(SETTINGS_MESSAGE.UPDATE_USER_SUCCESS)
  } catch (error) {
    yield put(disableLoading())
    console.log(error)
    yield toastr.error(SETTINGS_MESSAGE.UPDATE_USER_FAILED)
    // set error
  }
}
