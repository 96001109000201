import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  QueryDocumentSnapshot,
  DocumentReference,
  arrayUnion
} from 'firebase/firestore/lite'

import FirestoreAPI from './FirestoreAPI'
import FBDB from 'db/firebase.config'
import { customerCollection } from 'db/collections'

import { now, mapCustomerDataToUI } from 'api/apiUtils'

import { TCommentData, TCustomerData } from 'types/customer.types'

class CustomerFirestoreAPI extends FirestoreAPI {
  static async getAll(withTimestamp: boolean = false) {
    const customersSnapshot = await getDocs(customerCollection)

    return (
      customersSnapshot.docs.map((customer: QueryDocumentSnapshot<TCustomerData>) => ({
        id: customer.id,
        ...mapCustomerDataToUI(customer.data(), withTimestamp)
      })) || []
    )
  }

  static async createCustomer(customer: TCustomerData) {
    const currentTime = now()
    const newCustomerRef = await addDoc(customerCollection, {
      ...customer,
      createdAt: customer.createdAt ? customer.createdAt : currentTime,
      updatedAt: customer.updatedAt ? customer.updatedAt : currentTime,
      deletedAt: customer.deletedAt ? customer.deletedAt : null
    })
    // return new created customer
    const newCustomer = await getDoc(newCustomerRef)
    return {
      id: newCustomer.id,
      ...mapCustomerDataToUI(newCustomer.data()!)
    }
  }

  static async updateCustomer(customer: TCustomerData) {
    const currentTime = now()
    const { id, ...rest } = customer
    // check if id presented
    if (!id) throw new Error('Customer Id is not available.')

    const customerRef = doc(FBDB, CustomerFirestoreAPI.prefixedTable('customers'), id)
    const customerSnap = await getDoc(customerRef)
    // check if customer exists
    if (!customerSnap.exists()) throw new Error('Customer does not exist.')

    await updateDoc(customerRef, { ...rest, updatedAt: currentTime })
  }

  static async commentCustomer(customerId: string, comment: TCommentData) {
    const customerRef = doc(
      FBDB,
      CustomerFirestoreAPI.prefixedTable('customers'),
      customerId
    ) as DocumentReference<TCustomerData>

    await updateDoc(customerRef, {
      comments: arrayUnion({
        message: comment.message,
        date: comment.date
      })
    })
  }
}

export default CustomerFirestoreAPI
