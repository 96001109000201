import { TAuthAction } from 'types/auth.types'
import { AUTH, USER } from 'app/constants'

export const authReducer = (state: boolean = false, action: TAuthAction) => {
  if (action.type === AUTH.VERIFY_SUCCESS) return true

  if (action.type === USER.LOGIN_SUCCESS) return true

  if (action.type === USER.LOGOUT) return false

  return state
}
