/**
 * Set menu item class to active when current URI match menu item path
 * @param {string} currentURI
 * @param {string} toMatch
 * @returns {string}
 */
const getMenuItemClass = (currentURI: string, toMatch: string): string => {
  if (toMatch === '/dashboard' && currentURI === '/') {
    return 'active'
  }
  return currentURI === toMatch ? 'active' : ''
}

export { getMenuItemClass }
