import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  QueryDocumentSnapshot
} from 'firebase/firestore/lite'
import { loanerCollection } from 'db/collections'

import FirestoreAPI from './FirestoreAPI'
import { mapLoanerDataToUI, now } from 'api/apiUtils'
import FBDB from 'db/firebase.config'

import { TLoanerData } from 'types/loaner.types'

class LoanerFirestoreAPI extends FirestoreAPI {
  static async getAllLoaners(withTimestamp: boolean) {
    const loanersSnapshot = await getDocs(loanerCollection)

    return loanersSnapshot.docs.reduce(
      (loaners: TLoanerData[], loaner: QueryDocumentSnapshot<TLoanerData>) => {
        if (!loaner.data().deletedAt) {
          loaners.push({
            id: loaner.id,
            ...mapLoanerDataToUI(loaner.data(), withTimestamp)
          })
        }
        return loaners
      },
      []
    )
  }

  static async createLoaner(loaner: TLoanerData) {
    const currentTime = now()
    const newLoanerRef = await addDoc(loanerCollection, {
      ...loaner,
      availability: loaner.availability !== false,
      createdAt: loaner.createdAt ? loaner.createdAt : currentTime,
      updatedAt: loaner.updatedAt ? loaner.updatedAt : currentTime,
      deletedAt: loaner.deletedAt ? loaner.deletedAt : null
    })
    // return new created loaner
    const newLoaner = await getDoc(newLoanerRef)
    return {
      id: newLoaner.id,
      ...mapLoanerDataToUI(newLoaner.data()!)
    }
  }

  static async updateLoaner(loaner: TLoanerData) {
    const currentTime = now()
    const { id, ...rest } = loaner
    // check if id presented
    if (!id) throw new Error('Loaner Id is not available.')

    const loanerRef = doc(FBDB, LoanerFirestoreAPI.prefixedTable('loaners'), id)
    const loanerSnap = await getDoc(loanerRef)
    // check if loaner exists
    if (!loanerSnap.exists()) throw new Error('Loaner does not exist.')

    await updateDoc(loanerRef, { ...rest, updatedAt: currentTime })
  }

  static async deleteLoaner(loanerId: string) {
    const loanerRef = doc(FBDB, LoanerFirestoreAPI.prefixedTable('loaners'), loanerId)
    const loanerSnapshot = await getDoc(loanerRef)

    // check if loaner exists
    if (!loanerSnapshot.exists()) throw new Error('Loaner does not exist.')

    await updateDoc(loanerRef, { deletedAt: now() })
  }
}

export default LoanerFirestoreAPI
