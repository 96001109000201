import AgreementFirestoreAPI from './firestore/agreementFirestoreAPI'

import { TAgreementData } from 'types/agreement.types'

/**
 * Get all agreement list
 * @returns {Object[]}
 */
export const getAllAgreements = async (): Promise<TAgreementData[]> => {
  return await AgreementFirestoreAPI.getAllAgreements()
}

/**
 *  Save a new agreement into database
 * @param {Object} agreement
 * @returns {Object} agreement
 */
export const createAgreement = async (agreement: TAgreementData): Promise<TAgreementData> => {
  return await AgreementFirestoreAPI.createAgreement(agreement)
}

/**
 *  import agreements into database
 * @param {Object} agreement
 * @returns {Object} agreement
 */
export const importAgreement = async (agreement: TAgreementData): Promise<TAgreementData> => {
  return await AgreementFirestoreAPI.importAgreement(agreement)
}

export const exportAgreement = async (): Promise<TAgreementData[]> => {
  return await AgreementFirestoreAPI.exportAgreement()
}

/**
 * View agreement by Id
 * @param {string} agreementId
 */
export const viewAgreementPDF = async (agreementId: string): Promise<void> => {
  await AgreementFirestoreAPI.viewAgreementPDF(agreementId)
}

/**
 * Return loaner by agreement Id
 * @param {string} agreementId
 * @returns {string} loanerId
 */
export const returnLoaner = async (agreementId: string): Promise<string | null | undefined> => {
  return await AgreementFirestoreAPI.returnLoaner(agreementId)
}
