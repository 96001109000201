import React from 'react'

import { ValidationRules, FieldValues, UseFormMethods } from 'react-hook-form'
import { FormWrapper } from 'app/styles/form.styles'

export type TSelectOption = {
  label: string
  value: string | number
}

declare type TFieldPorps = {
  label: string
  value: any
  name: string
  options: TSelectOption[]
  validationRules?: ValidationRules
  formHook: UseFormMethods<FieldValues>
  hideError?: boolean
}

const Select: React.FC<TFieldPorps> = ({
  label,
  value,
  name,
  options,
  validationRules,
  formHook,
  hideError
}) => {
  const { register, errors } = formHook
  return (
    <FormWrapper>
      <label htmlFor={name}>{label}</label>
      <select
        className={errors?.[name] ? 'field-error' : ''}
        data-test-id={`${name}_input`}
        name={name}
        defaultValue={value}
        ref={register({ ...validationRules })}
      >
        {options.map(
          (option: TSelectOption, index: number): JSX.Element => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            )
          }
        )}
      </select>
      {!hideError && errors?.[name]?.type === 'required' && (
        <span data-test-id="error-message" className="error">
          {`${label} is required.`}
        </span>
      )}
    </FormWrapper>
  )
}

export default Select
