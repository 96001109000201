import makeStyles from '@material-ui/core/styles/makeStyles'
import { colour } from './globalStyles'

export const stepStyles = makeStyles({
  primaryIcon: {
    '& > span > span:first-child > svg': {
      '& circle, & path': {
        fill: colour.blue
      }
    }
  }
})
