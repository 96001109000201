import { IMPORT_EXPORT } from 'app/constants'
import { TImportOpt, TImportFileInfo } from 'types/importExport.types'
import { FileUpload } from 'use-file-upload'

export const exportDataAction = (entity: string) => ({
  type: IMPORT_EXPORT.EXPORT_DATA,
  entity
})

export const importDataAction = (opt: TImportOpt, data: any[]) => ({
  type: IMPORT_EXPORT.IMPORT_DATA,
  opt,
  data
})

export const processFile = (file: FileUpload) => ({
  type: IMPORT_EXPORT.PROCESS_FILE,
  file
})

export const processFileSuccess = (fileContent: TImportFileInfo) => ({
  type: IMPORT_EXPORT.PROCESS_FILE_SUCCESS,
  fileContent
})
