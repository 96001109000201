import FBDB from './firebase.config'
import { collection, CollectionReference } from 'firebase/firestore/lite'

import mapAccounts from 'db/accounts'

import { TCustomerData } from 'types/customer.types'
import { TLoanerData } from 'types/loaner.types'
import { TAgreementData } from 'types/agreement.types'
import { TUserData } from 'types/auth.types'
import { TSettingsData } from 'types/settings.types'

const prefixedTable = (table: string): string => {
  const prefix = mapAccounts(window.location.hostname)
  return prefix + table
}

export const customerCollection = collection(
  FBDB,
  prefixedTable('customers')
) as CollectionReference<TCustomerData>

export const loanerCollection = collection(
  FBDB,
  prefixedTable('loaners')
) as CollectionReference<TLoanerData>

export const agreementCollection = collection(
  FBDB,
  prefixedTable('agreements')
) as CollectionReference<TAgreementData>

export const userCollection = collection(
  FBDB,
  prefixedTable('users')
) as CollectionReference<TUserData>

export const settingsCollection = collection(
  FBDB,
  prefixedTable('settings')
) as CollectionReference<TSettingsData>
