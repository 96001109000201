import React from 'react'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'

import TextArea from 'app/components/common/formElement/TextArea'

import { commentCustomerAction } from 'app/store/actions'
import { DATE_TO_DB } from 'app/constants'

import { TCustomerCommentFormProps } from './customerCommentForm.types'
import { TCommentData } from 'types/customer.types'

import { buttonStyle } from 'app/styles/button.styles'
import { FormButtonWrapper, StickyBottomForm } from 'app/styles/form.styles'

const CommentForm: React.FC<TCustomerCommentFormProps> = ({ customerId, closeFlyinWindow }) => {
  const dispatch = useDispatch()
  const classes = buttonStyle()
  const formHook = useForm()

  const onSubmit = (data: TCommentData) => {
    dispatch(commentCustomerAction(customerId, { ...data, date: moment().format(DATE_TO_DB) }))
    closeFlyinWindow(null)
  }

  return (
    <>
      <StickyBottomForm
        onSubmit={formHook.handleSubmit(onSubmit)}
        data-test-id="customer-comment-form"
      >
        <TextArea
          label="Comments"
          name="message"
          rows={5}
          data-test-id="customer-comment"
          placeholder="Please leave the comments"
          value=""
          validationRules={{ required: true }}
          formHook={formHook}
        />
        <FormButtonWrapper>
          <Button type="submit" className={classes.saveButton} data-test-id="comment-submit-button">
            Save
          </Button>
          <Button
            onClick={() => closeFlyinWindow(null)}
            className={classes.cancelButton}
            data-test-id="comment-cancel-button"
          >
            Cancel
          </Button>
        </FormButtonWrapper>
      </StickyBottomForm>
    </>
  )
}

export default CommentForm
