import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col } from 'app/components/common/grid'
import Password from 'app/components/common/formElement/Password'
import Button from '@material-ui/core/Button'
import Spinner from 'app/components/common/spinner'

import { getCurrentUserSelector, getLoadingState } from 'app/store/selector'
import { resetCurrentUserPassword } from 'app/store/actions'

import { FormButtonWrapper } from 'app/styles/form.styles'
import { buttonStyle } from 'app/styles/button.styles'

import { TPassword } from 'types/auth.types'

const UpdatePassword = () => {
  const dispatch = useDispatch()
  const classes = buttonStyle()
  const formHook = useForm()
  const newPassword = useRef()
  newPassword.current = formHook.watch('newPassword', '')

  const loading = useSelector(getLoadingState)
  const currentUser = useSelector(getCurrentUserSelector)
  const onSubmit = (password: TPassword) => {
    dispatch(resetCurrentUserPassword(currentUser.email, password))
  }

  if (loading) return <Spinner />

  return (
    <>
      <Row data-test-id="update-password-panel">
        <Col xs={8}>
          <h3>Change password</h3>
          <form onSubmit={formHook.handleSubmit(onSubmit)} data-test-id="update-password-form">
            <Password
              label="Current password"
              name="currentPassword"
              value=""
              formHook={formHook}
              validationRules={{ required: true }}
            />
            <Password
              label="New password"
              name="newPassword"
              value=""
              formHook={formHook}
              validationRules={{ required: true }}
            />
            <Password
              label="Confirm password"
              name="confirmPassword"
              value=""
              formHook={formHook}
              validationRules={{ validate: value => value === newPassword.current, required: true }}
            />
            <FormButtonWrapper>
              <Button
                type="submit"
                className={classes.saveButton}
                data-test-id="update-password-submit-button"
              >
                Update password
              </Button>
            </FormButtonWrapper>
          </form>
        </Col>
        <Col xs={4}></Col>
      </Row>
    </>
  )
}

export default UpdatePassword
