import find from 'lodash/find'

import Importer from './Importer'
import { createLoaner, updateLoaner } from 'api/loaner.api'
import { excelDateToJSDate, isNull, isTrue } from 'app/utils/importerUtils'

import { EDateFormat, IImporter, TImportMode } from 'types/importExport.types'
import { TLoanerData } from 'types/loaner.types'

class LoanerImporter extends Importer implements IImporter<TLoanerData> {
  private loaners: TLoanerData[] = []

  constructor(importMode: TImportMode) {
    super(importMode)
  }

  mapLoaner(data: any): TLoanerData {
    const {
      make,
      model,
      rego,
      year,
      transmission,
      colour,
      availability,
      createdAt,
      updatedAt,
      deletedAt
    } = data
    return {
      make,
      model,
      rego,
      year,
      transmission,
      colour,
      availability: isTrue(availability),
      createdAt: excelDateToJSDate(createdAt, EDateFormat.DATETIME),
      updatedAt: excelDateToJSDate(updatedAt, EDateFormat.DATETIME),
      deletedAt: !isNull(deletedAt) ? excelDateToJSDate(deletedAt, EDateFormat.DATETIME) : null
    }
  }

  import(data: any[]): void {
    data.forEach(async (loaner: any) => {
      const foundLoaner = find(this.loaners, { rego: loaner.rego })
      // checking if loaner exists
      if (foundLoaner === undefined) {
        const newLoaner = await createLoaner(this.mapLoaner(loaner))
        this.loaners.push(newLoaner)
      } else {
        if (this.importMode === 'override') {
          await updateLoaner(this.mapLoaner({ ...foundLoaner, ...loaner }))
        }

        if (this.importMode === 'duplicate') {
          const newLoaner = await createLoaner(this.mapLoaner(loaner))
          this.loaners.push(newLoaner)
        }
      }
      setTimeout(() => {}, 5000)
    })
  }
}

export default LoanerImporter
