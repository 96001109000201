import React from 'react'
import Paper from '@material-ui/core/Paper'
import { pageStyles, PageHeader } from 'app/styles/page.styles'
import './style.scss'

const NotFound: React.FC = () => {
  const classes = pageStyles()
  return (
    <>
      <PageHeader>
        <h1>Page Not Found</h1>
      </PageHeader>
      <Paper className={classes.paper}>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h3>Oops! Page not found</h3>
              <h1>
                <span>4</span>
                <span>0</span>
                <span>4</span>
              </h1>
            </div>
            <h2>we are sorry, but the page you requested was not found</h2>
          </div>
        </div>
      </Paper>
    </>
  )
}

export default NotFound
