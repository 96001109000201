import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'

import Spinner from 'app/components/common/spinner'
import CompanyProfile from 'app/components/forms/settings/companyProfile'
import UserProfile from 'app/components/forms/settings/userProfile'
import UpdatePassword from 'app/components/forms/settings/updatePassword'
import CreateUser from 'app/components/forms/settings/createUser'

import { generateTabAttribute } from './settings.utils'
import { getCurrentUser } from 'api/auth.api'

import { getSettings } from 'app/store/actions'

import { PageHeader, pageStyles, TabPanel } from 'app/styles/page.styles'
import { getLoadingState } from 'app/store/selector'

const currentUser = {} as any
const Settings = () => {
  const dispatch = useDispatch()
  const classes = pageStyles()
  const [tab, setTab] = useState<number>(0)

  const loading = useSelector(getLoadingState)

  useEffect(() => {
    dispatch(getSettings())
  }, [])

  if (loading) return <Spinner />

  const isRoot = currentUser.role === 'root'

  return (
    <>
      <PageHeader>
        <h1>Settings</h1>
      </PageHeader>
      <Paper className={classes.paper}>
        <Tabs value={tab} onChange={(e, newVal) => setTab(newVal)} aria-label="settings">
          <Tab label="Company Profile" {...generateTabAttribute('company-profile-tab')} />
          <Tab label="User Profile" {...generateTabAttribute('user-profile-tab')} />
          <Tab label="Update Password" {...generateTabAttribute('update-password-tab')} />
          {isRoot && <Tab label="Create User" {...generateTabAttribute('create-user-tab')} />}
        </Tabs>
        <Divider />
        <TabPanel>
          {tab === 0 && <CompanyProfile />}
          {tab === 1 && <UserProfile />}
          {tab === 2 && <UpdatePassword />}
          {tab === 3 && <CreateUser />}
        </TabPanel>
      </Paper>
    </>
  )
}

export default Settings
