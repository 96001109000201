import LoanerFirestoreAPI from './firestore/loanerFirestoreAPI'
import { TLoanerData } from 'types/loaner.types'

/**
 * Get all loaner list
 * @returns {Object[]}
 */
export const getAllLoaners = async (withTimestamp: boolean = false): Promise<TLoanerData[]> => {
  return LoanerFirestoreAPI.getAllLoaners(withTimestamp)
}

/**
 *  Save a new loaner into database
 * @param {Object} loaner
 * @returns {Object} loaner
 */
export const createLoaner = async (loaner: TLoanerData): Promise<TLoanerData> => {
  return await LoanerFirestoreAPI.createLoaner(loaner)
}

/**
 * Update exists loaner with new data
 * @param {Object} loaner
 */
export const updateLoaner = async (loaner: TLoanerData): Promise<void> => {
  await LoanerFirestoreAPI.updateLoaner(loaner)
}

/**
 * Delete loaner by loaner id
 * @param {string} loanerId
 */
export const deleteLoaner = async (loanerId: string): Promise<void> => {
  await LoanerFirestoreAPI.deleteLoaner(loanerId)
}
