import React, { useState, useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import SelectWithSearch, {
  TSelectWithSearchOption
} from 'app/components/common/formElement/SelectWithSearch'

import { getAllCustomers } from 'app/store/selector'
import { getCustomers } from 'app/store/actions'

import { buttonStyle } from 'app/styles/button.styles'
import { FormButtonWrapper } from 'app/styles/form.styles'

import { TCustomerDetails, TCustomer } from './customerDetails.types'
import { TCustomerData } from 'types/customer.types'

const CustomerDetails: React.FC<TCustomerDetails> = ({ handleNext }) => {
  const dispatch = useDispatch()
  const [customer, setCustomer] = useState<TCustomer>()
  const formHook = useForm()
  const classes = buttonStyle()

  const customerRaw = useSelector(getAllCustomers)
  useEffect(() => {
    if (customerRaw.length === 0) dispatch(getCustomers())
  }, [])

  const customers = useMemo(
    () =>
      customerRaw.map((customer: TCustomerData) => {
        const { id, comments, ...rest } = customer
        return {
          label: `${customer.firstname} ${customer.lastname}`,
          value: { ...rest }
        }
      }),
    [customerRaw]
  )

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <h3>Customer details</h3>
              <SelectWithSearch
                label="Search customer"
                name="customer"
                value=""
                validationRules={{ required: true }}
                formHook={formHook}
                options={customers as any[]}
                onSelect={(customer: TSelectWithSearchOption) => {
                  setCustomer(customer.value)
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Driver Licence</TableCell>
            <TableCell>Phone Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customer && (
            <TableRow>
              <TableCell>{`${customer?.firstname}, ${customer?.lastname}`}</TableCell>
              <TableCell>{customer?.licence}</TableCell>
              <TableCell>{customer?.phone}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={3}>
              <FormButtonWrapper fullWidth>
                <Button className={classes.previousButton} disabled>
                  Back
                </Button>
                <Button
                  data-test-id="next-button"
                  className={classes.saveButton}
                  disabled={!customer}
                  onClick={() => handleNext(customer!)}
                >
                  Next
                </Button>
              </FormButtonWrapper>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}
export default CustomerDetails
