import { USER } from 'app/constants'
import { TUserData, TUserAction } from 'types/auth.types'

export const userReducer = (state = {} as TUserData, action: TUserAction) => {
  if (action.type === USER.SET_CURRENT_USER) return action.user

  if (action.type === USER.UPDATE_SUCCESS) return { ...state, ...action.userData }

  return state
}
