import React, { useEffect, useCallback, useRef } from 'react'
import SignaturePad from 'signature_pad'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'

import { colour } from 'app/styles/globalStyles'
import { buttonStyle } from 'app/styles/button.styles'
import { FormButtonWrapper } from 'app/styles/form.styles'

import { TCustomerSignature } from './customerSignature.types'

let signaturePad: SignaturePad

const CustomerSignature: React.FC<TCustomerSignature> = ({ handleBack, handleNext }) => {
  const classes = buttonStyle()
  let canvasRef = useRef<HTMLCanvasElement | null>(null)

  const initSignaturePad = useCallback((canvas: any) => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)
    signaturePad = new SignaturePad(canvas, {
      penColor: 'rgb(0,0,0)',
      backgroundColor: colour.lightGrey
    })
  }, [])

  useEffect(() => {
    initSignaturePad(canvasRef.current)
  }, [])

  const clear = useCallback(() => signaturePad && signaturePad.clear(), [signaturePad])

  const save = useCallback(() => {
    const signature = signaturePad.toDataURL()
    handleNext({ signature })
  }, [signaturePad])

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <h3>Customer Signature</h3>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <canvas id="signature-pad" data-test-id="signature-pad" ref={canvasRef} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}>
            <FormButtonWrapper fullWidth>
              <Button className={classes.previousButton} onClick={handleBack}>
                Back
              </Button>
              <Button className={classes.cancelButton} onClick={clear}>
                Clear
              </Button>
              <Button data-test-id="next-button" className={classes.saveButton} onClick={save}>
                Create
              </Button>
            </FormButtonWrapper>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default CustomerSignature
