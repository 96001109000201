import { CUSTOMER } from 'app/constants'
import { TCustomerData, TCommentData } from 'types/customer.types'

export const getCustomers = () => ({
  type: CUSTOMER.GET_ALL
})

export const setCustomers = (customers: TCustomerData[]) => ({
  type: CUSTOMER.GET_ALL_SUCCESS,
  customers
})

export const createCustomer = (customer: TCustomerData) => ({
  type: CUSTOMER.CREATE,
  customer
})

export const createCustomerSuccess = (customer: TCustomerData) => ({
  type: CUSTOMER.CREATE_SUCCESS,
  customer
})

export const updateCustomer = (customer: TCustomerData) => ({
  type: CUSTOMER.UPDATE,
  customer
})

export const updateCustomerSuccess = (customer: TCustomerData) => ({
  type: CUSTOMER.UPDATE_SUCCESS,
  customer
})

export const commentCustomerAction = (customerId: string, comment: TCommentData) => ({
  type: CUSTOMER.COMMENT,
  customerId,
  comment
})

export const commentCustomerSuccess = (customerId: string, comment: TCommentData) => ({
  type: CUSTOMER.COMMENT_SUCCESS,
  customerId,
  comment
})
