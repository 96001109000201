import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Chip from '@material-ui/core/Chip'

import PopoverOptions from 'app/components/common/popoverOption'
import ConfirmDialog from 'app/components/common/confirmDialog'
import Spinner from 'app/components/common/spinner'
import toastr from 'app/components/common/tips'

import { getAllAgreements, getLoadingState } from 'app/store/selector'
import { getAgreements, returnLoanerAction, viewAgreement } from 'app/store/actions'

import { TAgreementData } from 'types/agreement.types'

import { pageStyles } from 'app/styles/page.styles'
import { buttonStyle } from 'app/styles/button.styles'

const AgreementList = () => {
  const dispatch = useDispatch()
  const classes = { ...pageStyles(), ...buttonStyle() }
  const [isConfirmReturnLoaner, setIsConfirmReturnLoaner] = useState(false)
  const [page, setPage] = useState(0)
  const [currentAgreement, setCurrentAgreement] = useState<TAgreementData>()

  const rowPerPage = 10

  const agreements = useSelector(getAllAgreements)
  const loading = useSelector(getLoadingState)

  useEffect(() => {
    if (agreements.length === 0) dispatch(getAgreements())
  }, [])

  const customerReturnLoaner = (agreement: TAgreementData) => {
    setCurrentAgreement(agreement)
    setIsConfirmReturnLoaner(true)
  }

  const confirmReturnLoaner = useCallback(() => {
    dispatch(returnLoanerAction(currentAgreement?.id!))
    setIsConfirmReturnLoaner(false)
  }, [currentAgreement])

  const sortedAgreements = useMemo<TAgreementData[]>(() => {
    const leasing = [] as TAgreementData[]
    const returned = [] as TAgreementData[]
    agreements.forEach(agreement => {
      if (agreement.returned) {
        returned.push(agreement)
      } else {
        leasing.push(agreement)
      }
    })
    return [...leasing, ...returned]
  }, [agreements.length])

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Table aria-label="Agreement list">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Driver Licence</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Make</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Rego</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAgreements
              .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
              .map((agreement: TAgreementData) => (
                <TableRow key={agreement.id}>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    {agreement.lastname
                      ? `${agreement.lastname}, ${agreement.firstname}`
                      : `${agreement.firstname}`}
                  </TableCell>
                  <TableCell>{agreement.licence}</TableCell>
                  <TableCell>
                    <Chip
                      variant="outlined"
                      size="small"
                      className={agreement.returned ? classes.returned : classes.leasing}
                      label={agreement.returned ? 'Returned' : 'Leasing'}
                    />
                  </TableCell>
                  <TableCell>{agreement.make}</TableCell>
                  <TableCell>{agreement.model}</TableCell>
                  <TableCell>{agreement.year}</TableCell>
                  <TableCell>{agreement.rego}</TableCell>
                  <TableCell align="right" className={classes.options}>
                    <PopoverOptions id={`${agreement.id!}`} Trigger={MoreVertIcon}>
                      <Button
                        onClick={() => {
                          dispatch(viewAgreement(agreement.id!))
                        }}
                        data-test-id="preview-agreement"
                        className={classes.optionsButton}
                      >
                        Preview
                      </Button>
                      {!agreement.returned && (
                        <Button
                          onClick={() => customerReturnLoaner(agreement)}
                          data-test-id="return-loaner"
                          className={classes.optionsButton}
                        >
                          Return
                        </Button>
                      )}
                    </PopoverOptions>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={agreements.length}
          rowsPerPage={10}
          page={page}
          onPageChange={(e, num) => setPage(num)}
        />
      </Paper>
      <ConfirmDialog
        isOpen={isConfirmReturnLoaner}
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        confirmAction={confirmReturnLoaner}
        cancelAction={() => setIsConfirmReturnLoaner(false)}
      >
        <p>
          {currentAgreement && (
            <strong>
              Do you want to return {currentAgreement.make} {currentAgreement.model}{' '}
              {currentAgreement.rego} ?
            </strong>
          )}
        </p>
      </ConfirmDialog>
    </>
  )
}

export default AgreementList
