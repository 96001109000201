import React from 'react'
import { useLocation } from 'react-router-dom'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined'
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined'
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Fab from '@material-ui/core/Fab'

import { getMenuItemClass } from 'app/utils/menuUtils'
import defaultLogo from 'app/image/logo.png'

import { MenuItem, Nav, navStyle, Account, MenuStyle } from './menu.style'
import { menuProps } from './menu.types'

const Menu: React.FC<menuProps> = ({ companyLogo, userAvatar, logout }) => {
  const classes: MenuStyle = navStyle()
  const match = useLocation()
  return (
    <Drawer
      open
      variant="permanent"
      classes={{
        root: classes.root,
        paper: classes.nav
      }}
    >
      {/*TODO: fetch logo from server if cannot find logo from server, fallback to local logo*/}
      <img
        src={companyLogo ? companyLogo : defaultLogo}
        alt=""
        width="100"
        style={{ margin: '20px auto 0' }}
      />
      <Nav>
        <MenuItem to={'/dashboard'} className={getMenuItemClass(match.pathname, '/dashboard')}>
          <DashboardOutlinedIcon /> <span>Dashboard</span>
        </MenuItem>
        <MenuItem to={'/agreement'} className={getMenuItemClass(match.pathname, '/agreement')}>
          <DescriptionOutlinedIcon /> <span>Loaner Agreement</span>
        </MenuItem>
        <MenuItem to={'/search'} className={getMenuItemClass(match.pathname, '/search')}>
          <FindInPageOutlinedIcon /> <span>Search Penalty</span>
        </MenuItem>
        <MenuItem to={'/customer'} className={getMenuItemClass(match.pathname, '/customer')}>
          <PeopleOutlinedIcon /> <span>Customer</span>
        </MenuItem>
        <MenuItem to={'/loaner'} className={getMenuItemClass(match.pathname, '/loaner')}>
          <DirectionsCarOutlinedIcon /> <span>Loaner</span>
        </MenuItem>
        <Divider className={classes.divider} />
        {/* <MenuItem to={'/user'} className={getMenuItemClass(match.pathname, '/user')}>
          <PeopleOutlineIcon /> <span>Users</span>
        </MenuItem> */}
        <MenuItem to={'/settings'} className={getMenuItemClass(match.pathname, '/settings')}>
          <SettingsOutlinedIcon /> <span>Settings</span>
        </MenuItem>
        <MenuItem
          to={'/import-export'}
          className={getMenuItemClass(match.pathname, '/import-export')}
        >
          <ImportExportIcon /> <span>Import / Export</span>
        </MenuItem>
      </Nav>
      <Divider />
      <Account>
        <Avatar alt="User Name" src={userAvatar} />
        <Fab
          data-test-id="user-logout-button"
          onClick={logout}
          size="small"
          className={classes.logout}
        >
          <PowerSettingsNewIcon />
        </Fab>
      </Account>
    </Drawer>
  )
}

export default Menu
