import { takeEvery, call, put } from '@redux-saga/core/effects'
import toastr from 'Toastr.config'

import {
  createAgreementSuccess,
  setAgreements,
  disableLoading,
  enableLoading,
  updateLoanerAvailabilitySuccess,
  returnLoanerSuccess
} from 'app/store/actions'

import {
  getAllAgreements,
  createAgreement,
  returnLoaner,
  viewAgreementPDF
} from 'api/agreement.api'

import { AGREEMENT, AGREEMENT_MESSAGE } from 'app/constants'

import { TAgreementData, TAgreementParam } from 'types/agreement.types'

export default function* watchAgreementSaga() {
  yield takeEvery(AGREEMENT.GET_ALL, handleGetAgreements)
  yield takeEvery(AGREEMENT.CREATE, handleCreateAgreement)
  yield takeEvery(AGREEMENT.RETURN, handleReturnLoaner)
  yield takeEvery(AGREEMENT.VIEW, handleViewAgreement)
}

function* handleGetAgreements() {
  try {
    yield put(enableLoading())
    const agreements: TAgreementData[] = yield call(getAllAgreements)
    yield put(setAgreements(agreements))
    yield put(disableLoading())
  } catch (error) {
    // setError
    yield put(disableLoading())
    yield toastr.error(AGREEMENT_MESSAGE.LOAD_FAILED)
  }
}

function* handleCreateAgreement(param: TAgreementParam) {
  try {
    yield put(enableLoading())
    const newAgreement: TAgreementData = yield call(createAgreement, param.agreement!)
    yield put(createAgreementSuccess(newAgreement))
    yield put(updateLoanerAvailabilitySuccess(newAgreement.loanerId!, false))
    yield put(disableLoading())
    yield toastr.success(AGREEMENT_MESSAGE.CREATE_SUCCESS)
  } catch (error) {
    // setError
    yield put(disableLoading())
    yield toastr.error(AGREEMENT_MESSAGE.CREATE_FAILED)
  }
}

function* handleReturnLoaner(param: TAgreementParam) {
  try {
    yield put(enableLoading())
    const loanerId: string = yield call(returnLoaner, param.agreementId!)
    yield put(updateLoanerAvailabilitySuccess(loanerId, true))
    yield put(returnLoanerSuccess(param.agreementId!))
    yield put(disableLoading())
    yield toastr.success(AGREEMENT_MESSAGE.RETURN_SUCCESS)
  } catch (error) {
    // setError
    yield put(disableLoading())
    yield toastr.error(AGREEMENT_MESSAGE.RETURN_FAILED)
  }
}

function* handleViewAgreement(param: TAgreementParam) {
  try {
    yield call(viewAgreementPDF, param.agreementId!)
  } catch (error) {
    // setError
    yield toastr.error(AGREEMENT_MESSAGE.VIEW_PDF_FAILED)
  }
}
