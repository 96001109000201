/**
 * Generate tab attributes
 * @param {string} id
 * @returns {{'aria-controls': string, id: string, 'data-test-id': string}}
 */
export const generateTabAttribute = (id: string) => ({
  id,
  'aria-controls': id,
  'data-test-id': id
})
