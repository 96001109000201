import React, { useCallback } from 'react'

const useImage = (image: string, currentImage: string, defaultImage: string) => {
  const isDefaultImage = useCallback(() => {
    return image === currentImage || image === defaultImage
  }, [image, currentImage])

  return { isDefaultImage }
}

export default useImage
