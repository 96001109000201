import styled from 'styled-components'
import { colour } from 'app/styles/globalStyles'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const SIDE_BAR_WIDTH = '240px'

const Nav = styled.nav`
  margin: 20px 0;
  flex-grow: 1;
`

const MenuItem = styled(Link)`
  color: ${colour.light};
  cursor: pointer;
  display: flex;
  padding: 10px 20px;
  text-decoration: none;
  align-items: center;

  span {
    padding-left: 15px;
  }
  &:hover,
  &.active {
    color: ${colour.dark};
    text-decoration: none;
  }
`

const Account = styled.div`
  align=items: center;
  display: flex;
  justify-content: space-between;
  margin: 15px;
`

export type MenuStyle = {
  root: any
  nav: any
  divider: any
  logout: any
}

const navStyle = makeStyles(
  (): MenuStyle => ({
    root: {
      flexShrink: '0',
      width: SIDE_BAR_WIDTH
    },
    nav: {
      boxShadow: '0 0 5px 0px rgb(205, 210, 218) !important',
      minWidth: SIDE_BAR_WIDTH
    },
    divider: {
      margin: '15px 0 !important'
    },
    logout: {
      backgroundColor: colour.fail,
      color: 'white',
      '&:hover': {
        backgroundColor: colour.alert
      }
    }
  })
)

export { navStyle, Nav, MenuItem, Account }
